import { PageState, PageAction, INIT_PAGE, SET_PAGE_SLOT, PageSlotState,
        SET_PAGE_FORM, SET_PAGE_FORM_ELEMENT, SET_PAGE_VIEW } from "../actions/actions"

const initialPageState: PageState = {
    id: 'root',
    module: 'app',
    viewMode: 'default',
    defaultController: '/'
}
/*
const initialSlotState: IDataElement = {
    main: null
}
*/
const slotsReducer = (state: PageSlotState = {}, action: PageAction): PageSlotState => {
    switch (action.type) {
        case SET_PAGE_SLOT:
            return {
                ...state,
                [action.target]: action.data
            }
        default:
            return state;
    }
}

const formsReducer = (state: PageSlotState = {}, action: PageAction): PageSlotState => {
    switch (action.type) {
        case SET_PAGE_FORM:
            return {
                ...state,
                [action.target]: action.data
            }
        case SET_PAGE_FORM_ELEMENT:
            const inputsProp = action.data.inputs;
            const errors = action.data.errors;
            const inputs = inputsProp ? inputsProp : {}
            //console.log(inputs, errors, action);
            let key: string= ""
            let value:any = {}
            for(key in inputs) {
                value = inputs[key]
            }
            
            const valid = state[action.target] ? true : false;
            const oldState = valid ? state : { ...state, [action.target]: {inputs:{},errors:{}} }
            
            return {
                ...state,
                [action.target] : {
                    inputs: {
                        ...oldState[action.target].inputs,
                        ...inputs,
                        [key]: { 
                            ...oldState[action.target].inputs[key],
                            ...value 
                        }
                        //[action.target] : {...inputs[action.target]}
                    },
                    errors: {
                        ...oldState[action.target].errors,
                        ...errors
                    }
                }
            }
        default:
            return state;
    }
}



const pageReducer = (state = initialPageState, action: PageAction): PageState => {
    
    switch (action.type) {
        case INIT_PAGE:
            return {
                ...state,
                ...action.config
            }
        case SET_PAGE_VIEW:
            return {
                ...state,
                viewMode: action.data
            }
        case SET_PAGE_SLOT:
            /*
            console.log('pageReducer',{
                ...state,
                //[action.target]: action.data //slots(state[action.target], action)
                slots: slotsReducer(state.slots, action)
            })
            */
            return {
                ...state,
                //[action.target]: action.data //slots(state[action.target], action)
                slots: slotsReducer(state.slots, action)
            }
        case SET_PAGE_FORM_ELEMENT:
        case SET_PAGE_FORM:
            return {
                ...state,
                forms: formsReducer(state.forms, action)
            }
            /*
        case SET_PAGE_FORM_ELEMENT:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.target] formsReducer(state.forms, action)
                }
            }
            */
        default:
            return state;
    }
}

export default pageReducer;
