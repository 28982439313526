import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import { ApplicationState } from '../../store/store';
import { connect } from 'react-redux';
import { ApplicationUser } from '@models/index';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UploadButton } from '@components/Common/FileManager';
import { Link } from 'react-router-dom';
import { Application } from '@services/index';

const BASE_URL = process.env.REACT_APP_API_URL;

export interface SidebarUserBlockProps extends WithTranslation {
    showUserBlock: boolean,
    currentUser?: ApplicationUser
}

class SidebarUserBlock extends Component<SidebarUserBlockProps> {

    state = {
        showUserBlock: false
    }

    componentWillReceiveProps(newProps: SidebarUserBlockProps) {
        if (newProps.showUserBlock !== this.props.showUserBlock || this.state.showUserBlock !== newProps.showUserBlock) {
            this.setState({ showUserBlock: newProps.showUserBlock })
        }
    }

    render() {
        const { currentUser } = this.props;
        //const avarar = currentUser && [BASE_URL,currentUser.avatar,`?q=${Date.now()}`].join("") || 'img/user/default-avatar.jpg'
        const avatar = currentUser && currentUser.avatar;
        return (
            <Collapse id="user-block" isOpen={ this.state.showUserBlock }>
                <div>
                    <Link  to="/user-profile" style={{textDecoration: 'none'}}>
                        <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail rounded-circle" src={avatar} alt="Avatar" width="60" height="60" />                                
                                <div className="circle bg-success circle-lg"></div>
                                {/*currentUser && <UploadButton id="updateAvatar" url={path} itemData={item} />*/}
                            </div>
                        </div>
                        {/* Name and Job */}
                        
                            <div className="user-block-info">
                                <span className="user-block-name">{this.props.i18n.t("HELLO")}, { currentUser && currentUser.firstName}</span>
                                <span className="user-block-role">{currentUser && currentUser.username}</span>
                                <span className="user-block-role">{currentUser && currentUser.groupName}</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </Collapse>
        )
    }
}


const mapStateToProps = (state: ApplicationState) => (
    { showUserBlock: state.settings.showUserBlock, currentUser: state.session.user }
)

export default connect(
    mapStateToProps
)(withTranslation('translations')(SidebarUserBlock));