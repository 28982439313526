import { http } from "@helpers/http.helper";
import { ApiResult, ApiResultCodes } from "@models/index";
import { Application } from "./app.service";

const BASE_URL = process.env.REACT_APP_SUBASTA_API_URL; 

export enum TipoObra {
    Urbanizacion = 1,
    ObrasCabecera = 2,
    Libre = 3
}

class SubastaService {

    public ESTATUS_OPTS = [
        { value: 1, code: 'R', color:'rgb(255, 143, 0)', label: "Registro" },
        { value: 2, code: 'C', color:'rgb(51, 122, 182)', label: "Convocatoria" }, //'rgb(255, 192, 0)'
        { value: 3, code: 'D', color:'rgb(223, 7, 7)', label: "Desierta" },
        { value: 4, code: 'O', color:'rgb(255, 192, 0)', label: "Alta Propuestas" }, //'rgb(214, 211, 211)'
        { value: 5, code: 'P', color:'rgb(139, 199, 128)', label: "Puja" },
        { value: 6, code: 'A', color:'#333', label: "Anulada" },
        { value: 7, code: 'D', color: '#f05050', label: "Deliberacion" },
        { value: 8, code: 'F', color: '#23b7e5', label: "Fallo" } //#333
    ];
    
    getActivas(estatus: string, term: string):string {
        return `${BASE_URL}projects/tenders?estatus=${estatus}&term=${term}`;
    } 

    getArchivadas(estatus: string, term: string):string {
        return `${BASE_URL}projects/tenders/archive?estatus=${estatus}&term=${term}`;
    } 

    getParticipantesParaGanador(id:number):string {
        return `${BASE_URL}projects/tenders/${id}/result`;
    } 

    anular(id:number):string {
        return `${BASE_URL}projects/tenders/${id}/cancel`;
    } 

    view(id:number):string {
        return `${BASE_URL}projects/tenders/${id}`;
    } 

    obtenerReporteEjecutivo(id:number):string {
        return `${BASE_URL}projects/tenders/${id}/executive-report`;
    } 

    getUrlCargaDocumentos(id:number, uid:number, docId:number) {
        return `${BASE_URL}projects/tenders/${id}/contestant/${uid}/documents/${docId}`;
    }

    getUrlCargaConstanciaIva(id:number, uid:number) {
        return `${BASE_URL}projects/tenders/${id}/contestant/${uid}/vat`;
    }

    getAllPosts(id:number) {
        const url = `${BASE_URL}projects/tenders/${id}/blog/posts`;
        return url;
    }

    getAllComments(blogid: number, postid: number) {
        const url = `${BASE_URL}projects/tenders/${blogid}/blog/posts/${postid}/comments`;
        return url;
    }

    async removerNotificaciones(id: number) {
        const url = `${BASE_URL}projects/tenders/${id}/notifications`;
        return http.del(url)
        .then(response => {
            //console.log("response", response)
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al eliminar")
                return false;
            }
            return true;
        })
    }

    async actualizarConcursantes(id:number) {
        const url = `${BASE_URL}projects/tenders/${id}/contestant/refresh`;
        return http.get(url)
        .then(response => {
            //console.log("response", response)
            if (response.code !== ApiResultCodes.Ok) return response;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al eliminar")
                return r;
            }
            return r;
        })
    }

    descargarReporteEjecutivo(id: number,param:string) {
        const url = `${BASE_URL}projects/tenders/${id}/executive-report/export?param=${param}`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            //console.log("response", response)
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    getPostFile(blogId: number,postId:number,fileId:number) {
        const url = `${BASE_URL}projects/tenders/${blogId}/blog/posts/${postId}/files/${fileId}`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    getCommentFile(blogId: number,postId:number,fileId:number) {
        const url = `${BASE_URL}projects/tenders/${blogId}/blog/posts/${postId}/comments/files/${fileId}`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    async deletePost(blogId: number, postId:number) {
        const url = `${BASE_URL}projects/tenders/${blogId}/blog/posts/${postId}`;
        return http.del(url).then(response => {
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al eliminar la publicacion")
                return false;
            }
            return true;
        })
    }

    async deleteComment(blogId: number, postId:number, commentId:number) {
        const url = `${BASE_URL}projects/tenders/${blogId}/blog/posts/${postId}/comments/${commentId}`;
        return http.del(url).then(response => {
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al eliminar el comentario")
                return false;
            }
            return true;
        })
    }

    async guardaOferta(subastaId:number, uid:number, data:any) {
        const url = `${BASE_URL}projects/tenders/${subastaId}/contestant/${uid}/offers`;
        return http.post(url, data).then( response => {
            if (response.code !== ApiResultCodes.Ok) return response;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al guardar la oferta")
            }
            return r
        })
    }

    async autorizarOferta(subastaId:number, uid:number, data:any) {
        const url = `${BASE_URL}projects/tenders/${subastaId}/contestant/${uid}/offers/authorize`;
        return http.post(url, data).then( response => {
            if (response.code !== ApiResultCodes.Ok) return response;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al guardar la oferta")
            }
            return r
        })
    }

    async enviarInvitaciones(subastaId:number) {
        const url = `${BASE_URL}projects/tenders/${subastaId}/send-invitation`;
        return http.post(url, {}).then( response => {
            if (response.code !== ApiResultCodes.Ok) return response;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al enviar las invitaciones")
            }
            return r
        })
    }

    async enviarDesicion(subastaId:number) {
        const url = `${BASE_URL}projects/tenders/${subastaId}/send-decision`;
        return http.post(url, {}).then( response => {
            if (response.code !== ApiResultCodes.Ok) return response;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al enviar la decisión")
            }
            return r
        })
    }
    
    /*
    getPost(id:number, postId: number) {
        const url = `${BASE_URL}projects/tenders/${id}/blog/posts/${postId}`;
    }
    */

   getDocumentoIVA(id: number,uId:number) {
        const url = `${BASE_URL}projects/tenders/${id}/contestant/${uId}/vat`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    getDocumento(id: number,docId:number) {
        const url = `${BASE_URL}projects/tenders/${id}/documents/${docId}`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    getDocumentosTecnicos(id: number,uId:number) {
        const url = `${BASE_URL}projects/tenders/${id}/contestant/${uId}/documents/technical`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    async putDocumentosTecnicos(id: number,uId:number, data: any) {
        const url = `${BASE_URL}projects/tenders/${id}/contestant/${uId}/documents/technical`;
        return await http.put(url, data)
        .then(response => {
            if (response.code !== ApiResultCodes.Ok) return response;
            //console.log("putDocumentosTecnicos", response)
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Error al validar los documentos")
                //return;
            }
            return r;
        })
    } 

    getDocumentosEconomicos(id: number,uId:number) {
        const url = `${BASE_URL}projects/tenders/${id}/contestant/${uId}/documents/economic`;
        Application.toast("Preparando descarga...")
        http.get(url)
        .then(response => {
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 

    getDocumentos(id: number,docId:number) {
        const url = `${BASE_URL}projects/tenders/${id}/documents/${docId}`;
        Application.notify("Preparando descarga...")
        http.get(url)
        .then(response => {
            if (response.code !== ApiResultCodes.Ok) return false;
            const r:ApiResult = response.data[0];
            if (r.code !== ApiResultCodes.Ok) {
                Application.error(r.message || "Documento no existe")
                return;
            }
            const filename:string = r.data[0].filename;
            const fd = `${BASE_URL}files/download?filename=${filename}`
            window.open(fd, "_blank")
        })
    } 
}

export const Subastas = new SubastaService();