
import { store } from '../store/store';
import { apiAction } from '../store/actions/api.actions';
import { HttpMethod, http, history } from '@helpers/helpers.module';
import { ApiResult, IDataElement, DataElement, ApplicationUser } from '../models'; 
import { cssTransition, toast } from 'react-toastify';
import { Store } from './store.service';
import { CancelToken } from 'axios';
import moment, { unitOfTime } from 'moment-timezone'
import { Notification } from '@components/common.module';
import swal from 'sweetalert';

const BASE_URL = process.env.REACT_APP_API_URL;

class AppService {

    public Store:Store = new Store();

    constructor() { 
        //console.log("AppModule: loaded"); 
    }

    public env = {
        get: (varName: string) => process.env["REACT_APP_"+varName]
    }

    dispatchGet = (action: string, url: string, slot: string, cancelToken?: CancelToken) => {
        store.dispatch(apiAction({
            url,
            actionType : action,
            slot,
            cancelToken
        }))
    }

    dispatchDel = (action: string, url: string, cb?: (r: ApiResult) => void) => {
        store.dispatch(apiAction({
            method: HttpMethod.DELETE,
            url,
            actionType : action,
            slot: "",
            onSuccess: cb
        }))
    }

    dispatchPost = (action: string, url: string, data: any, slot: string, cb?: (r: ApiResult) => void) => {
        store.dispatch(apiAction({
            method: HttpMethod.POST,
            url,
            actionType: action,
            slot,
            data,
            onSuccess: cb,
            onFailure: cb
        }))
    }

    private restoreState = (element: IDataElement) => {
        console.log("restoreState", element);
    }

    dispatchPut = (action: string, url: string, data: any, slot: string, cb?: (r: ApiResult) => void) => {
        //this.Store.getState()
        store.dispatch(apiAction({
            method: HttpMethod.PUT,
            url,
            actionType: action,
            slot,
            data,
            onSuccess: cb,
            onFailure: cb
        }))
    }

    dispatch = (action: string, payload: any = {}, slot?: string) => {
        if (slot != undefined)
            payload = { target: slot, data: payload };
        
        store.dispatch({
            type: action,
            ...payload
        })
    }

    dispatchToSlot = (type: string, target:string, data: any) => {
        const payload:any = { target, data };

        store.dispatch({
            type,
            ...payload
        })
    }
    /*
    get = (url: string, slotOrSection: string, actionType: string, successCallback?: (response: any) => void, errorCallback?: (error: any) => void) => {

        store.dispatch(apiAction({
            url,
            onSuccess: successCallback,
            onFailure: errorCallback, //() => console.log("Error occured loading articles"),
            actionType,
            slot: slotOrSection
        }))
    }
    */
    notify = (message: any, title?:any) => Notification.notify(message,title);
    warning = (message: any, title?:any) => Notification.warning(message,title);
    error = (message: any, title?:any) => Notification.error(message,title);
    success = (message: any, title?:any) => Notification.success(message,title);
    information = (title:string, props?:any) => swal({title, ...props})
    confirm = (title: string, text?:string, icon:string ='info', props?:any) => {
        let options = props ? { ...props } : {}
        if (!options.options) {
            options.buttons = {
                cancel: {
                    text: 'No!',
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true
                },
                confirm: {
                    text: 'Continuar',
                    value: true,
                    visible: true,
                    className: "bg-success",
                    closeModal: true
                }
            }
        }
        return swal({ title, text, icon, ...options})
    }
    
    notify2 = (message: string) => toast(message, {
        type: 'info',
        position: "top-right"
    })

    error2 = (message: string) => toast(message, {
        type: "error",
        position: "top-right"
    })
    
    warning2 = (message: string) => toast(message, {
        type: "warning",
        position: "top-right"
    })
    
    success2 = (message: string) => toast(message, {
        type: "success",
        position: "top-right"
    })

    toast = (message: string) => toast(message, {
        type: "default",
        position: "bottom-center",
        hideProgressBar: true,
        autoClose:2000,
        className:"toast-dark",
        closeButton: false,
        transition: cssTransition({
            enter: 'zoomIn',
            exit: 'zoomOut',
            duration: [500, 600],
            appendPosition: false
          })
    })

    navigateToPath(path: string) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        //console.log(window.location);

        //window.location.href = path;
        history.push(path);
        //window.location.replace(url);
    }

    navigateToUrl(url: string) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        //console.log(window.location);
        window.location.replace(url);
    }

    createAbsoluteUrl(relativePath: string) {
        const BASE_URL = process.env.REACT_APP_API_URL;
        return `${BASE_URL}${relativePath}`
    }

    createDocumentUrl(filetype: string, filename: string) {
        const BASE_URL = process.env.REACT_APP_API_URL;
        return `${BASE_URL}KontrolFiles/GetTemp/${filetype}/${filename}`
    }

    formatCurrency(value: number):any {
        //return <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
        //return new NumberFormat({ value, displayType:'text', thousandSeparator:true, prefix:'$', decimalScale: 2 }).render();
        const num = new Number(value);
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }


    /*
    getCurrentPath: () => string = (): string => {
        try {
            return BrowserRouter. .hashHistory.getCurrentLocation().pathname;
        } catch (e) {
            return "";
        };
    };

    go: (url: string, relative?: boolean) => void = (url: string, relative?: boolean): void => {
        if (relative === true) {
            url = [getCurrentPath(), url].join("/");
        };

        let data: string = global.encodeObject({ url });

        location.href = "#/kontrol/go/" + data;
    };

    */

    getUser():IEntityUser {
        const user:IEntityUser = Application.Store.getState("session:user");
        return user
    }

    getUserAvatar(user:IEntityUser | ApplicationUser):string {
        return [BASE_URL,user.avatar,`?q=${Date.now()}`].join("") || 'img/user/default-avatar.jpg'
    }

    getTimezone():IEntityTimezone {
        const timezone:IEntityTimezone = Application.Store.getState("global:application:data:timezone");
        return timezone
    }

    getUserTimezone():IEntityTimezone {
        const timezone:IEntityTimezone = Application.Store.getState("global:application:data:userTimezone");
        return  timezone // || {id:0, clave:null, nombre:null}
    }

    getLocalTimeZone() {
        const tz = moment.tz.guess(true);
        const timezones:IEntityTimezone[] = Application.Store.getState("global:application:entities:zonaHorarias");
        const index = timezones.findIndex( x => x.clave === tz);
        return timezones[index]
    }

    getfileIcon(ext:string) {
        ext = ext.toLowerCase();
        if (["pdf"].includes(ext))
            return "far fa-file-pdf"
        if (["jpg","jpeg","png","gif","bmp","svg"].includes(ext))
            return "far fa-file-image"
        if (["xls","xlsx","csv"].includes(ext))
            return "far fa-file-excel"
        if (["doc","docx"].includes(ext))
            return "far fa-file-word"
        if (["zip","rar"].includes(ext))
            return "far fa-file-archive"
        if (["ppt"].includes(ext))
            return "far fa-file-powerpoint"
        if (["txt"].includes(ext))
            return "far fa-file-alt"
        if (["mp4","avi","mkv","mov"].includes(ext))
            return "far fa-file-video"
        if (["mp3","wav","ogg"].includes(ext))
            return "far fa-file-audio"
        return "fal fa-file"
    }
}

export interface IEntityTimezone {
    id: number
    clave: string
    nombre: string
}

export interface IEntityUser {
    id: number
    username: string
    firstName: string
    lastName: string
    avatar: string
    profile: number
    groupName: string
    phone: string
}

export const Application = new AppService();