
export const path = {
    getBasename,
    getDirname,
    getExtension,
    getFilename,
    getFilenameWithoutExtension
}

function getBasename(path: string) {
    return path.replace(/.*\//, '');
}

function getDirname(path: string) {
    return path.match(/.*\//);
}

function getExtension(path: string) {
    let filename = getBasename(path)
    return filename.split('.').pop() || "";
}

function getFilename(path: string) {
    var startIndex = (path.indexOf('\\') >= 0 ? path.lastIndexOf('\\') : path.lastIndexOf('/'));
    var filename = path.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }
    return filename; //.split('.').shift();
}

function getFilenameWithoutExtension(path: string) {
    var filename = getFilename(path);
    return filename.split('.').shift() || "";
}