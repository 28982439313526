import * as React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { QueryParameterNames } from '../../models'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/store';
import { path } from '@helpers/path.helper';
import { Application } from '@services/index';

interface ProtectedRouteState {
    ready: boolean
}

class ProtectedRoute extends Component<any, ProtectedRouteState> {

    constructor(props: any) {
        super(props);
        
        this.state = {
            ready: false
        };
    }

    componentDidMount() {
        this.setState({ ready: true });
    }

    shouldComponentUpdate(nextProps: any, nextState: ProtectedRouteState) {
        
        //console.log("ProtectedRoute:shouldComponentUpdate", nextProps.authenticated, this.props.authenticated, nextState.ready, this.state.ready, this.props.path, nextProps.path )

        return nextProps.authenticated !== this.props.authenticated 
                || nextProps.appready !== this.props.appready
                || nextState.ready !== this.state.ready
                || nextProps.path !== this.props.path;
    }
    
    render() {
        const { ready } = this.state;
        const { authenticated, appready } = this.props;
        let returnUrl = encodeURI(window.location.href);
        if (returnUrl.includes(QueryParameterNames.ReturnUrl)) returnUrl = `${window.location.origin}${process.env.REACT_APP_FOLDER}`

        //const redirectUrl = path.getBasename(returnUrl) !== "logout" ? `/login?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}` : `${process.env.REACT_APP_FOLDER}`;
        const redirectUrl = path.getBasename(returnUrl) !== "logout" ? `/login?${QueryParameterNames.ReturnUrl}=${returnUrl}` : `${process.env.REACT_APP_FOLDER}`;

        if (!ready) {
            return <div></div>;
        } else if (authenticated && !appready) {
            return <div>Inicializando...</div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

}

function Inicializando() {
    return (<div>Inicializando...</div>);
}

const mapStateProps = (state: ApplicationState, ownProps: any) => ({
    authenticated: !!state.session.user,
    appready: state.global.appready
});

export default connect(mapStateProps)(ProtectedRoute);

