import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { toggleSetting, signOut } from '../../store/actions/actions';

import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderSearch from './HeaderSearch';
import swal from 'sweetalert';
import { Authentication, Store } from '@services/index';
import { store, ApplicationState } from '@store/store';
import { AppNotification } from '@models/index';

type HeaderProps =  {
    toggleSetting: typeof toggleSetting,
    signOut: typeof signOut,
    notifications: AppNotification[]
}


class Header extends Component<HeaderProps> {

    state = {
        navSearchOpen: false
    };

    toggleUserblock = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('asideToggled');
    }

    resize () {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        document.dispatchEvent(event);
    }

    toggleNavSearch: React.MouseEventHandler = e => {
        e.preventDefault();
        this.setState({
            navSearchOpen: !this.state.navSearchOpen
        });
    };

    closeNavSearch: React.EventHandler<any> = e => {
        e.preventDefault();
        this.setState({
            navSearchOpen: false
        });
    };

    logout() {
        this.props.signOut();
        /*
        const that = this;
        if (Authentication) {
            Authentication.signOut();
            //this.props.signOut();
        }
        */    
        return;
        swal({ title: "¿Está seguro de cerrar la sesión?", 
            //text: "Your imaginary file has been deleted.", 
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Regresar a la aplicación",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true
                },
                confirm: {
                    text: "Sí, cerrar sesión!",
                    value: true,
                    visible: true,
                    className: "bg-danger",
                    closeModal: true
                }
            }
        }).then( (response:boolean) => {
            
            //if (response) this.props.dispatch({ type: authenticationConstants.LOGOUT})
            //Authentication.signOut()
            //this.props.dispatch({type: authenticationConstants.LOGOUT})
            //that.props.signOut();
        });
    }

    goto(path: string) {
        
    }

    render() {
        let notificationsCount = 0
        if (this.props.notifications)
            for (const item of this.props.notifications) {
                notificationsCount += item.count
            }
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                                <span className="text-white ml-2" style={{fontFamily:'JuliusSansOne', fontSize:'1.725rem', fontWeight: 'bold', position:'absolute',}}>ilavo</span>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        { /* START User avatar toggle */ }
                        <li className="nav-item d-none d-md-block">
                            <a  className="nav-link" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li>
                        { /* END User avatar toggle */ }
                        { /* START logout */ }
                        <li className="nav-item d-none d-md-block">
                            <Link to="/logout" title="Logout" className="nav-link">
                                <em className="icon-logout"></em>
                            </Link>
                        </li>
                        { /* END logout */ }
                        { /* START lock screen */ }
                        {/*
                        <li className="nav-item d-none d-md-block">
                            <Link to="/lock" title="Lock screen" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li>
                        */}
                        { /* END lock screen */ }
                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* Search icon */ }
                        {/*
                        <li className="nav-item">
                            <a className="nav-link" onClick={this.toggleNavSearch}>
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>
                        */}
                        { /* Fullscreen (only desktops) */ }
                        {/*
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link"/>
                        </li>
                        */}
                        { /* START Alert menu */ }
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                <em className="icon-bell"></em>
                                {notificationsCount > 0 && <span className="badge badge-danger">{notificationsCount}</span>}
                            </DropdownToggle>
                            { /* START Dropdown menu */ }
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem>
                                    { /* START list group */ }
                                    <ListGroup>
                                        { this.props.notifications && 
                                        this.props.notifications.map( notification => <ListGroupItem key={`appnotification${notification.id}`} action tag="a" href={`${process.env.REACT_APP_FOLDER}${notification.url}` }>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className={notification.icon}></em>
                                             </div>
                                             <div className="media-body">
                                                <p className="m-0">{notification.title}</p>
                                                <p className="m-0 text-muted text-sm">{notification.count} nuevas notificaciones</p>
                                             </div>
                                          </div>
                                        </ListGroupItem> ) }
                                       { this.props.notifications && this.props.notifications.length === 0 &&
                                       <ListGroupItem action tag="a" href="" onClick={e => e.preventDefault()}>
                                          <span className="d-flex align-items-center">
                                             <span className="text-sm">No hay notificaciones</span>
                                          </span>
                                       </ListGroupItem>
                                       }
                                    </ListGroup>
                                    { /* END list group */ }
                                </DropdownItem>
                            </DropdownMenu>
                            { /* END Dropdown menu */ }
                        </UncontrolledDropdown>
                        { /* END Alert menu */ }
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-notebook"></em>
                            </a>
                        </li>
                        { /* END Offsidebar menu */ }
                    </ul>
                    { /* END Right Navbar */ }

                    {/* START Search form */}
                    <HeaderSearch isOpen={this.state.navSearchOpen} onClose={this.closeNavSearch} />
                    {/* END Search form */}
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }

}

const mapStateProps = (state: ApplicationState, ownProps: any) => {
    const notifications = state.global && state.global.notifications;
    
    return {
        notifications
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({ 
    toggleSetting: bindActionCreators(toggleSetting, dispatch) ,
    signOut: bindActionCreators(signOut, dispatch) 
})

export default connect(
    mapStateProps,
    mapDispatchToProps
)(Header);