import { WithTranslation } from "react-i18next"
import { Dispatch, AnyAction } from "redux"
import { RouteComponentProps } from "react-router"

export interface DispatchProps {
    dispatch: Dispatch<AnyAction>
}

export type DefaultProps = WithTranslation & DispatchProps & RouteComponentProps

export interface IDataElement {
    data: any
    loaded?: boolean
    loading?: boolean
    error?: any
    timestamp?: number

    isLoading: boolean
    //updatedAt: number
    lastUpdated: number
    invalidate?: boolean
    status: DataElementStatus

    page?: number
    pageCount?: number
    firstPageUrl?: string
    nextPageUrl?: string
    previousPageUrl?: string
    lastPageUrl?: string
}

export class DataElement implements IDataElement {
    data: any = null
    isLoading = false
    invalidate = false
    //updatedAt = Date.now()
    lastUpdated = Date.now()

    loaded: boolean = false
    loading: boolean = false
    error: any = null
    timestamp: number = 0;//Date.now()
    status: DataElementStatus = DataElementStatus.Default

    constructor(dataElement?: IDataElement) {
        if (dataElement !== undefined) {
            const { data, lastUpdated, isLoading, status } = dataElement;
            this.data = data;
            this.lastUpdated = lastUpdated
            this.isLoading = isLoading
            this.status = status;
        }
    }

    getItems(): Array<any> {
        if (this.data === null || this.data === undefined) return [];
        return this.data.constructor.name === 'Array' ? this.data : [this.data];
    }

    getItem():any {
        if (this.data === null || this.data === undefined) return null;
        return this.data.constructor.name === 'Array' ? (this.data.length > 0 ? this.data[0] : null) : this.data;
    }

    getData(): any {
        return this.getItem()
    }

    getItemField(field: string):any {
        const data = this.getItem();
        return data && data.hasOwnProperty(field) ? data[field] : data;
    }

    setData(value: any, status: DataElementStatus = DataElementStatus.Default): DataElement {
        this.data = value;
        this.isLoading = [DataElementStatus.Loading, DataElementStatus.Updating].includes(status);
        this.status = status;
        return this;
    }

    count(): number {
        if (this.data === null || this.data === undefined) return -1;
        return this.data.constructor.name === 'Array' ? this.data.length : 1;
    }

    toObject(): IDataElement {
        return {
            data: this.data,
            isLoading: this.isLoading,
            lastUpdated: this.lastUpdated,
            status: this.status,
            invalidate: this.invalidate
        }
    }

    isReady():boolean {
        return this.status === DataElementStatus.Ready
    }

    /*
    compareTimestamp = (target: IDataElement): number => {
        if (target == null || target == undefined) return 0;
        if (this.timestamp === target.timestamp) return 0;
        return (this.timestamp > target.timestamp) ? 1 : -1;
    }

    isMoreRecentThan = (target: IDataElement): boolean => {
        if (target == null || target == undefined) return true;
        return this.timestamp > target.timestamp;
    }

    hasUpdate = (target: IDataElement): boolean => {
        if (target == null || target == undefined) return false;
        return this.timestamp < target.timestamp;
    }

    */
}

export enum DataElementStatus {
    Error = -1,
    Default = 0,
    Loading = 1,
    Updating = 1,
    Ready = 2,
}