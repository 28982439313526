/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import './styles/helerok.scss'
import 'react-datetime/css/react-datetime.css';
import { store } from './store/store';
import { Authentication } from './services';
import ErrorBoundary from '@components/Common/ErrorBoundary';

declare var PUBLIC_URL: string;

class App extends Component {
    constructor(props: any) {
        super(props);
        //Authentication.updateState(store.getState().session.user);
        //console.log("Auth.Service:completeSignIn (app init)")
        //Authentication.completeSignIn(store.getState().session.user, true);
        
    }
    componentDidMount() {
      Authentication.completeSignIn(store.getState().session.user, true);
    }

  render() {
    
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    const basename = process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/');

    return (
        <BrowserRouter basename={basename}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
        </BrowserRouter>
    );

  }
}

export default App;
