import { http } from "@helpers/http.helper";


export const log = {

    sendReport: (message: any) => {
        if (process.env.NODE_ENV !== 'development')
            http.post(process.env.REACT_APP_LOG_URL || "log-error", message)
    },
    i: (...args:any) => {
        if (process.env.NODE_ENV === 'development')
            console.log("Logger", new Date().toLocaleTimeString(), ">>", ...args);
    },
    w: (...args:any) => {
        if (process.env.NODE_ENV === 'development')
            console.warn("Logger", new Date().toLocaleTimeString(), ">>", ...args);
    },
    e: (...args:any) => {
        if (process.env.NODE_ENV === 'development')
            console.error("Logger", new Date().toLocaleTimeString(), ">>", ...args);
    }
}

//export const logger = new LoggerService()