
import { ApiAction, API_START, API_SUCCESS, API_END, API_ERROR } from "../actions/actions";

export default function (state:any = {}, action: ApiAction) {
    //console.log("action type ", action.type);

    switch (action.type) {
        case API_START:
            return {
                ...state,
                [action.label]: action.payload
            }
        case API_SUCCESS:
            return {
                ...state,
                [action.label]: action.payload
            }
        case API_END:
            break;
        case API_ERROR:
            return {
                ...state,
                [action.label]: action.payload
            }
        default:
            break;
    }
    return state;

}
