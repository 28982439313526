import React, { Component } from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { changeSetting } from '../../store/actions/actions';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';

import Menu, { ISidebarMenu, ISidebarMenuItem } from '../../Menu';
import { DataElement } from '@models/index';
import { ApplicationState } from '@store/store';
import { throws } from 'assert';

export interface SidebarItemHeaderProps {
    item: ISidebarMenuItem
}
/** Component to display headings on sidebar */
const SidebarItemHeader = ({item} : SidebarItemHeaderProps) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

export interface SidebarItemProps {
    item: ISidebarMenuItem,
    isActive: Boolean
}
/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}: SidebarItemProps) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path!} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
)

export interface SidebarSubItemProps {
    item: ISidebarMenuItem,
    isActive: boolean,
    handler: (e: React.MouseEvent) => void,
    children: React.ReactNode,
    isOpen: boolean
}
/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen} : SidebarSubItemProps) => (
    <li className={ (isActive ? 'active' : '') + (item.submenu && !item.collapsible && isOpen ? ' animated slideInRight faster' : '') }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.submenu && !item.collapsible && !isOpen && <em className={`fas fa-arrow-circle-right float-right go-to-submenu`} style={{textAlign:'right', fontSize: '1.5em' }} hidden></em>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

export interface SidebarSubHeaderProps {
    item: ISidebarMenuItem
}
/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}: SidebarSubHeaderProps) => (
    <li className="sidebar-subnav-header"><Trans i18nKey={item.translate}>{item.name}</Trans></li>
)

interface StringBoolArray { [index: string] : boolean }

export interface SidebarProps extends RouteComponentProps, WithTranslation {
    badges?: any,
    changeSetting: typeof changeSetting
};
class Sidebar extends Component<SidebarProps> {

    state = {
        collapse: {} as StringBoolArray,
        parentMenu: null as ISidebarMenuItem[] | ISidebarMenuItem | null,
        currentItem: null as ISidebarMenuItem | null,
        stack: []
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator, this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
    }

    closeSidebar = () => {
        this.props.changeSetting({name: 'asideToggled', value: false});
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {} as StringBoolArray;
        Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                if (name)
                    collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse});
    }

    navigator = (route: string) => {
        route = process.env.NODE_ENV === 'development' ? route : route.replace(process.env.PUBLIC_URL || "","");
        this.props.history.push(route.replace('#','')); // remove '#' in case of use HashRouter
    }

    routeActive(paths: string | Array<string|undefined> | undefined) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => {
            return p && this.props.location.pathname.startsWith(p) 
            //return p && this.props.location.pathname.indexOf(p) > -1
        })
    }

    toggleItemCollapse(stateName: string) {
        const { collapse } =  this.state;
        for (let c in collapse) {
            if (collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    pushSubMenu(item: ISidebarMenuItem) {
        const state = {
            currentItem: item, 
            parentMenu : (this.state.currentItem ? this.state.currentItem : Menu)
        };
        this.setState({
            ...state,
            stack: [...this.state.stack, {currentItem: this.state.currentItem, parentMenu: this.state.parentMenu}]
        });
    }

    popSubMenu(item: ISidebarMenuItem) {
        const last:any = this.state.stack.pop();        
        this.setState({
            currentItem: this.state.stack.length > 0 ? last.currentItem : null, 
            parentMenu : this.state.stack.length > 0 ? last.parentMenu : null, 
            stack: [...this.state.stack]
        });
    }

    getSubRoutes = (item: any): any => item.submenu.map((e:any) => e.path);
    /*
        if(item.submenu)
            item.submenu.map(({path}) => path)
        return [];
    }
    */
    /** map menu config to string to determine which element to render */
    itemType = (item: ISidebarMenuItem) => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    renderMenu(currentMenu: ISidebarMenuItem[], currentItem: ISidebarMenuItem | null) {
        const { badges } = this.props;
        return currentMenu.map((item, i) => {
            // heading
            if(this.itemType(item) === 'heading')
                return (
                    <SidebarItemHeader item={item} key={i} />
                )
            else {
                if(this.itemType(item) === 'menu') {
                    const id = item.id || "";
                    if (badges && badges.hasOwnProperty(id) && badges[id].value > 0) 
                        item.label = { value: badges[id].value, color: badges[id].color }
                    else {
                        if (item.id) item.label = undefined;
                    }
                    return (
                        <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                    )
                }
                if(this.itemType(item) === 'submenu') {
                    if (!item.collapsible && currentItem && this.itemType(currentItem) === 'submenu' && currentItem.name) {
                        const lastItem:any = (this.state.stack[this.state.stack.length - 1] as any).currentItem;
                        const name = (lastItem && lastItem.name && lastItem.name) || 'al menú principal';
                        console.log("sub", this.state.stack[this.state.stack.length - 1] ,name, lastItem)
                        return <SidebarSubItem
                            key={`sidebarsubitem_${name}_${i}`}
                            item={{...currentItem, name: `Volver ${name}`,  icon:'fas fa-arrow-circle-left'}} isOpen={true}
                            handler={ this.popSubMenu.bind(this, currentItem) }
                            //handler = {() => this.setState({submenuItem: {}})}
                            isActive={this.routeActive(this.getSubRoutes(currentItem))}>
                            <SidebarSubHeader item={currentItem} />
                            {
                                currentItem.submenu && this.renderMenu(currentItem.submenu, null)
                            }
                        </SidebarSubItem>
                    }
                    const handler = (item:any) => item.collapsible ? this.toggleItemCollapse.bind(this,item.name || "") : this.pushSubMenu.bind(this, item); 
                    return item.name && [
                        <SidebarSubItem
                            item={{...item}} isOpen={item.collapsible ? this.state.collapse[item.name] : false}

                            handler={ handler(item)}
                            isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                            <SidebarSubHeader item={item} key={i}/>
                            {
                                item.submenu && item.submenu.map((subitem, i) =>
                                    <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                )
                                
                            }
                        </SidebarSubItem>
                    ]
                }
            }
            return null; // unrecognized item
        })
    }

    render() {
        const { badges } = this.props;
        const { currentItem, parentMenu } = this.state;
        const currentItemMenu = currentItem && !Array.isArray(currentItem) ? [currentItem] : null
        const currentParentMenu = parentMenu && !Array.isArray(parentMenu) ? [parentMenu] : null
        const currentMenu:ISidebarMenu = currentItemMenu || Menu;
        //console.log("currebt",currentMenu, currentItemMenu, currentItem && [currentItem],this.state.parentMenu)
        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar show-scrollbar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

                            { /* Iterates over all sidebar items */ }
                            {
                                /*
                                currentItem && 
                                this.itemType(currentItem) === 'submenu' &&
                                currentItem.name ?
                                    <SidebarSubItem
                                        item={{...currentItem, name: `Volver al menú principal`,  icon:'fas fa-arrow-circle-left'}} isOpen={true}
                                        handler={ this.toggleItemCollapse.bind(this, currentItem) }
                                        //handler = {() => this.setState({submenuItem: {}})}
                                        isActive={this.routeActive(this.getSubRoutes(currentItem))}>
                                        <SidebarSubHeader item={currentItem} />
                                        {
                                            currentItem.submenu && currentItem.submenu.map((subitem, i) =>
                                                <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                            )
                                        }
                                    </SidebarSubItem> :*/
                                this.renderMenu(currentMenu, currentItem)
                                    /*
                                currentMenu.map((item, i) => {
                                    // heading
                                    if(this.itemType(item) === 'heading')
                                        return (
                                            <SidebarItemHeader item={item} key={i} />
                                        )
                                    else {
                                        if(this.itemType(item) === 'menu') {
                                            const id = item.id || "";
                                            if (badges && badges.hasOwnProperty(id) && badges[id].value > 0) 
                                                item.label = { value: badges[id].value, color: badges[id].color }
                                            else {
                                                if (item.id) item.label = undefined;
                                            }
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                            )
                                        }
                                        if(this.itemType(item) === 'submenu')
                                            return item.name && [
                                                <SidebarSubItem
                                                    item={{...item}} isOpen={this.state.collapse[item.name]}
                                                    handler={ this.toggleItemCollapse.bind(this, item) }
                                                    isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                                    <SidebarSubHeader item={item} key={i}/>
                                                    {
                                                        item.submenu && item.submenu.map((subitem, i) =>
                                                            <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                                        )
                                                        
                                                    }
                                                </SidebarSubItem>
                                            ]
                                    }
                                    return null; // unrecognized item
                                })
                                */
                            }
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

const mapStateProps = (state: ApplicationState, ownProps: any) => {
    const notifications = state.global.notifications && state.global.notifications.length > 0 && state.global.notifications[0];
    return { badges: notifications && notifications.menu }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({ changeSetting: bindActionCreators(changeSetting, dispatch) })

export default connect(
    mapStateProps,
    mapDispatchToProps
)(withTranslation('translations')(withRouter(Sidebar)));
