import ErrorPage from '../../modules/base/ErrorPage';
import * as React from 'react';
import BasePage from '@components/Layout/BasePage';
import { http } from '@helpers/http.helper';
import { log } from '@helpers/logger.helper';

export default class ErrorBoundary extends React.Component<any> {
  state = { hasError: false, error: undefined };

  recover() {
      this.setState({ hasError: false });  
      //this.props.history.push("/");
  }

  componentDidCatch(error:Error, info:any) {
    // Display fallback UI
    this.setState({ hasError: true, error });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    log.sendReport({ 
      message: error.message, 
      stack: error.stack,
      componentStack: info.componentStack, 
      name: error.name, 
      url: window.location.href, 
      //userAgent: window.navigator.userAgent
    });
    //log.i(error, info, window.location)
    //http.post(process.env.REACT_APP_LOG_URL || "log-error", { message: error.message, stack: error.stack, name: error.name})
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.FallbackComponent || <BasePage><ErrorPage error={this.state.error} recover={this.recover} /></BasePage> || <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
