
import { Middleware } from 'redux'
import axios, { AxiosResponse, AxiosError } from "axios"

import { apiError, apiStart, apiEnd, API, apiSuccess, apiRequestCallback, SET_PAGE_SLOT } from "../actions/actions";
import { DataElement, DataElementStatus, ApiResult, ApiResultCodes, QueryParameterNames, IDataElement } from '../../models';
import { Authentication, Application } from '../../services';

const BASE_URL = process.env.REACT_APP_API_URL; //'https://localhost:44300/api/';

const api: Middleware = ({ dispatch }) => next => action => {
    let result = next(action);

    if (action.type !== API) return result;

    const accessToken = Authentication.getAccessToken();

    const {
        url,
        method,
        data,
        headers,
        onSuccess,
        onFailure,
        actionType,
        slot,
        cancelToken        
    } = action.payload;

    const relativeUrl = (!url.startsWith('https:') && !url.startsWith('http:'));
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    const label = [actionType, slot].join('$');
    //url: relativeUrl ? `${BASE_URL}${url}` : url,
    const endpoint: string =  relativeUrl ? `${BASE_URL}${url}` : url;

    // axios default configs
    axios.defaults.baseURL = ""; //process.env.REACT_APP_BASE_URL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    const element: DataElement = new DataElement()
    element.isLoading = true;
    
    const backupElement = Application.Store.getActionStateDataElement(actionType, slot) || element;

    if (slot) {
        dispatch(apiStart(label)); 
        backupElement.isLoading = true;
        dispatch(apiRequestCallback(actionType, slot, backupElement.toObject()))
    }

    axios
        .request({
            url: endpoint,
            method,
            headers,
            [dataOrParams]: data,
            cancelToken
        })
        .then((response: AxiosResponse) => {
            /*
            console.log("api:middleware:success", response);
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                Authentication.signOut();
                window.location.reload(true);
            }
            */
            const result: ApiResult = response.data;
            const error = result.code != ApiResultCodes.Ok;

            const backupElement = Application.Store.getActionStateDataElement(actionType, slot);

            if (!error) dispatch(apiSuccess(label, result)); 

            element.setData(result.data);
            element.isLoading = false;
            element.invalidate = false;
            element.lastUpdated = Date.now();
            element.status = DataElementStatus.Ready;
            
            if (onSuccess) onSuccess(result);
            

            switch (result.code) {
                case ApiResultCodes.Information:
                    Application.notify(result.message || "");
                    break;
                case ApiResultCodes.Error:
                    element.status = DataElementStatus.Error;
                    Application.error(result.message || "");
                    break;
                case ApiResultCodes.NotFound:
                case ApiResultCodes.Warning:
                    element.status = DataElementStatus.Error;
                    Application.warning(result.message || "");
                    break;
                default:
                    break;
            }

            //dispatch(apiRequestCallback(actionType, slot, element.toObject()))
            if (error && backupElement) {
                backupElement.isLoading = false;
                dispatch(apiRequestCallback(actionType, slot, backupElement.toObject()))
            } else {
                dispatch(apiRequestCallback(actionType, slot, element.toObject()))
            }
            
        })
        .catch( async (error: AxiosError | any) => {
            const response = error.response || { status: 0, headers: {} };
            if (response.status === 401) {
                if (response.headers["token-expired"]) {
                    const refreshToken = await Authentication.refreshToken();
                    if (refreshToken.code === ApiResultCodes.Ok) {
                        return dispatch(action);
                    }
                }
                //Authentication.logOut();
                Authentication.signOut();
            }
            if (response.status === 403) {
                Application.warning("Acceso denegado al recurso que solicita "+error.message);
            } else {
                if (!error.__CANCEL__) Application.warning(error.message);
            }

            element.setData(null);
            element.isLoading = false;
            element.invalidate = false;
            element.lastUpdated = Date.now();
            element.status = DataElementStatus.Error;

            dispatch(apiRequestCallback(actionType, slot, element.toObject()))
            dispatch(apiError(label, error));

            if (onFailure) onFailure(error);
        })
        .finally(() => {
            if (slot) {
                dispatch(apiEnd(label));
            }
        });
};

export default api;