import { log } from '@helpers/logger.helper';
import { GlobalState, GlobalAction, SET_GLOBAL, APP_DATA, SET_ENTITY, CLEAR_GLOBAL, APP_ERROR } from '../actions/actions';

const initialState: GlobalState = {
    application: {
        data: {
            version: ""
        },
        entities: {},
        isLoading: false,
        lastUpdated: 0
    }
}

const appReducer = (state: any, action: GlobalAction) => {
    switch (action.type) {
        case APP_DATA:
            const data = action.data.data && action.data.data[0]
            const entities = action.data.data && action.data.data[0].entities
            if (data) delete data['entities']
            return {
                ...state,
                data: data,
                entities: entities,
                isLoading: action.data.isLoading,
                lastUpdated: action.data.lastUpdated
            }
        default:
            return state;
    }
}

const globalReducer = (state = initialState, action: GlobalAction): GlobalState => {
    switch (action.type) {
        case SET_GLOBAL:
            return {
                ...state,
                [action.target]: action.data || action.payload
            };
        case APP_DATA:
            return {
                ...state,
                application: appReducer(state.Application, action)
            }
            return state;
        case SET_ENTITY:
            return state;
        case CLEAR_GLOBAL:
            return initialState
        case APP_ERROR:
            log.i(action)
            return state;
        default:
            return state;
    }
}

export default globalReducer;

