export enum EstatusOrden {
  Cancelado = -1,
  Solicitado = 1,
  Recibida = 2,
  EnCamino = 3,
  Recolectado = 4,
  EnProceso = 5,
  Terminado = 6,
  Enviado = 7,
  Entregado = 8
}



export enum EstatusFactura {
  SinFactura = 0,
  Solicitada = 1,
  Enviada = 2
}

export interface OrdenServicio {
  id: number;
  orderID: string;
  ticket: number;
  venta: string;
  estatusId:number;
  estatus: string;
  descuento_monedero: number;
  avance: number;
  pagada: boolean;
  infoPago: string;
  solicitarFactura: boolean;
  inventariada: boolean;
  prendasInventariadas: number;
  atendida: boolean;
  cancelada: boolean;
  motivoCancelacion: string;
  usuarioCancelacion: string;
  fechaCancelacion: string;
  subtotal: number;
  total: number;
  puntosRedimidos: number;
  pagado: number;
  cambio: number;
  recolectado: boolean;
  entregado: boolean;
  indicacionesCliente: string;
  comentariosSocio?: any;
  comentariosTienda?: any;
  calificacionCliente: number;
  calificacionClienteComentarios?:string;
  calificacionSocio: number;
  calificacionSocioComentarios?:string;
  formaPago: string;
  rate: number;
  recoleccion: Recoleccion;
  entrega: Recoleccion;
  atiende: UsuarioOrden;
  cliente: UsuarioOrden;
  tienda: Tienda;
  factura: Factura;
  pago: Pago;
  cantidad: number;
  servicios: OrdenServicioPartida[];
  inventario: any[];
  actions: Actions;
  actualizando: boolean;
  motivosCancelacion: MotivoCancelacion[];
  socios: Socio[]
}

interface Socio {
  id:number
  nombre:string
  perfil:string
  telefono:string
  calificacion:number
  avatar:string
}

interface MotivoCancelacion {
  id: string;
  descripcion: string;
  eliminado: string;
}

interface Actions {
  sync: boolean;
  pay: boolean;
  cancel: boolean;
  rate: boolean;
  editPickup: boolean;
  editDelivery: boolean;
}

export interface OrdenServicioPartida {
  partida: number;
  cantidad: number;
  descripcion: string;
  descripcionExtra?: any;
  precio: number;
  precioRegular: number;
  importe: number;
}

interface Pago {
  forma_pago: string;
  autorizacion: string;
  creado: string;
  tarjeta: string;
  cuenta: string;
  cargo?: any;
  operacion?: any;
  estatus?: any;
}

interface Factura {
  nombre: string;
  rfc: string;
  direccion: string;
  email: string;
  estatusId:number;
  estatus: string;
}

interface Tienda {
  nombre: string;
  tel: string;
}

export interface UsuarioOrden {
  id: number;
  nombre: string;
  avatar: string;
  telefono: string;
}

interface Cliente {
  id: number;
  nombre: string;
  avatar: string;
  telefono: string;
}

interface Atiende {
  nombre: string;
  telefono: string;
  avatar: string;
}

interface Recoleccion {
  empleado: string;
  direccion: string;
  ubicacion: string;
  indicaciones: string;
  fecha: string;
}
/*
export interface OrdenServicio {
    id: number;
    orderID: string;
    ticket: number;
    venta: string;
    estatus: string;
    avance: number;
    pagada: boolean;
    infoPago: string;
    solicitarFactura: boolean;
    inventariada: boolean;
    prendasInventariadas: number;
    atendida: boolean;
    cancelada: boolean;
    motivoCancelacion: string;
    usuarioCancelacion: string;
    fechaCancelacion?: any;
    subtotal: number;
    total: number;
    puntosRedimidos: number;
    pagado: number;
    cambio: number;
    recolectado: boolean;
    entregado: boolean;
    indicacionesCliente?: any;
    comentariosSocio?: any;
    comentariosTienda?: any;
    formaPago: string;
    rate: number;
    recoleccion: Recoleccion;
    entrega: Recoleccion;
    atiende: Atiende;
    cliente: Cliente;
    tienda: Tienda;
    factura: Factura;
    pago: Pago;
    cantidad: number;
    servicios: any[];
    inventario: any[];
    actions: Actions;
    actualizando: boolean;
  }
  
  interface Actions {
    sync: boolean;
    pay: boolean;
    cancel: boolean;
    rate: boolean;
    editPickup: boolean;
    editDelivery: boolean;
  }
  
  interface Pago {
    forma_pago: string;
    autorizacion: string;
    creado: string;
    tarjeta: string;
    cuenta: string;
    cargo: string;
    operacion?: any;
    estatus?: any;
  }
  
  interface Factura {
    nombre: string;
    rfc: string;
    direccion: string;
    email: string;
    estatus: string;
  }
  
  interface Tienda {
    nombre: string;
    tel: string;
  }
  
  interface Cliente {
    id: number;
    nombre: string;
    avatar: string;
    telefono: string;
  }
  
  interface Atiende {
    nombre: string;
    telefono: string;
    avatar: string;
  }
  
  interface Recoleccion {
    empleado: string;
    direccion: string;
    ubicacion: string;
    indicaciones?: any;
    fecha: string;
  }
*/