

import { ApplicationUser, IDataElement } from '../../models';
//import { AxiosError } from 'axios';

/*
export const authenticationConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN: 'USERS_LOGIN',
    LOGOUT: 'USERS_LOGOUT',
    REFRESH_TOKEN: 'USERS_REFRESH'
};
*/
export const LOGIN = 'USERS_LOGIN';
export const LOGOUT = 'USERS_LOGOUT';
export const REFRESH_TOKEN = 'USERS_REFRESH';

export interface AppSessionState {
    isLoading: boolean
    lastUpdated: number
    user?: ApplicationUser
    timezone?: any
    language?: any
}

/*
export const authorizeActions = {
    signIn,
    signOut
};
*/

interface LoginAction {
    type: typeof LOGIN
    target: string
    data: IDataElement
}

interface RefresTokenAction {
    type: typeof REFRESH_TOKEN
    target: string
    token: string
    refreshToken: string
}

interface LogoutAction {
    type: typeof LOGOUT
}
/*
interface AuthorizeDefaultAction {
    type: typeof authenticationConstants.LOGIN_REQUEST | typeof authenticationConstants.LOGIN_SUCCESS;
    user: ApplicationUser
}

interface AuthorizeErrorAction {
    type: typeof authenticationConstants.LOGIN_FAILURE
    error: string
}
*/
export type AppSessionAction = LoginAction | LogoutAction | RefresTokenAction; // | AuthorizeDefaultAction & AuthorizeErrorAction;

export function signOut(): AppSessionAction {
    return { type: LOGOUT };
}

/*
export function signIn(username: string, password: string) {
    return {
        type: authenticationConstants.LOGIN,

    }
}
*/
/*
export function signIn(username: string, password: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(request({ id: 0, firstName: '', username }));

        Authentication.signIn(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    //history.push('/cards'); *****************************************
                },
                error => {
                    //debugger;

                    //console.log("auth.signin:err", error, username, password, error.message, error.response.status, error.statusText);
                    dispatch(failure(error.message));
                    //dispatch(alertActions.error(error.message));
                }
            );
    };
}

function request(user: ApplicationUser) { return { type: authenticationConstants.LOGIN_REQUEST, user } }
function success(user: ApplicationUser) { return { type: authenticationConstants.LOGIN_SUCCESS, user } }
function failure(error: string) { return { type: authenticationConstants.LOGIN_FAILURE, error } }


function signOut() {
    Authentication.signOut();
    return { type: authenticationConstants.LOGOUT };
}
*/
/*
export const updateSession = (state: AppSessionState) => {
    Authentication.updateState(state.user);
}
*/
