import * as React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import { DefaultProps, IDataElement, DataElement } from '@models/index';
import { ApplicationState } from '@store/store';
import { connect } from 'react-redux';
import { PageSlotState } from '@store/actions/actions';
import { Application } from '@services/index';
import { CardTool, CardToolOnRemove, CardToolOnRemoved, CardToolOnRefresh, CardToolOnSaveItem, CardToolOnAddItem, CardToolOnViewItem } from './CardTool';

export interface IPanelActions {
    saveItem: boolean
    addItem: boolean
    view: boolean
}
 
export interface CardSectionProps extends DefaultProps {
    id?: string
    title: string | React.ReactNode
    icon: string
    datasource?: string | undefined
    isLoading: boolean
    showCounter: boolean
    style?: React.CSSProperties;

    containers: PageSlotState
    data: IDataElement[] | null
    actions: IPanelActions
    cardClassName?: string

    /* CardToolProps */
    hideCollapse:boolean
    addItem: boolean
    saveItem: boolean
    viewItem: boolean;
    refresh: boolean;
    dismiss: boolean;
    spinner: String;
    onRemove: CardToolOnRemove;
    onRemoved: CardToolOnRemoved;
    onRefresh: CardToolOnRefresh;
    onSaveItem: CardToolOnSaveItem;
    onAddItem: CardToolOnAddItem;
    onViewItem: CardToolOnViewItem;

    addItemIcon: string;
    saveItemIcon: string
    viewItemIcon: string;
}

export interface CardSectionState {}

class CardSectionClass extends React.Component<CardSectionProps, CardSectionState> {
    state = {
        activeTab: '1',
        key: 1,
        collapsed: false,
        // array with each accordion state (open/close)
        accordionState: [true, false, false],
        // choose to show open only one or more at the same time
        oneAtATime: true
    }

    static defaultProps = {
        cardClassName: "",
        hideCollapse: false,
        addItem: false,
        saveItem: false,
        viewItem: false,
        refresh: false,
        dismiss: false,
        onRemove: () => {},
        onRemoved: () => {},
        onRefresh: () => {},
        onSaveItem: () => {},
        onAddItem: () => {},
        onViewItem: () => {},
        spinner: 'ringed'
    };

    constructor(props: CardSectionProps) {
        super(props);
    }

    getDatasource():DataElement {
        if (this.props.datasource) {
            const datastore: any = Application.Store.getState(this.props.datasource);
            const dataElemet:IDataElement = Application.Store.isDataElement(datastore) ? datastore : Application.Store.createSuccesfulStoreObject(datastore);
            return new DataElement(dataElemet);
            //console.log("panel:getDatasource",e, Application.Store.isDataElement(datastore))
        } else {
            return new DataElement(Application.Store.createStoreObject(null))
        }
    }

    toggleAccordion = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }
    /*
    componentWillReceiveProps(nextProps: CardSectionProps) {
        if (Utils.hasChanges(this.props.containers, nextProps.containers)) {
            const dataElement: IDataElement = nextProps.containers[this.props.slot] || {};
            if (dataElement.isLoading) {
                
            }
        }
        //this.getData(nextProps.containers)
    }
    */
    getHeader(icon: string, title: string | React.ReactNode, count: number) {
        let showIcon = icon != undefined && icon != null && icon.trim().length > 0;
        const { showCounter } = this.props;

        return (
            <CardHeader className="border-bottom" >
                <span>
                    {showIcon ? <em className={icon} /> : ''}&nbsp;
                    {title} { showCounter ? <span className="badge badge-primary"> {count}</span> : null }
                </span>
                <CardTool collapsible={!this.props.hideCollapse} {...this.props} onToggle={this.toggleAccordion} />
            </CardHeader>
        );
    }



    render() {
        const { id, icon, title, isLoading, cardClassName } = this.props;
        const dataElement = this.getDatasource();

        const className = ['card-default', cardClassName,  dataElement.isLoading || isLoading ? 'whirl ringed' : ''].join(' ');
        //console.log("panel:render", icon, title, isLoading)
        return (
            <Card id={id} className={className} style={this.props.style}>
                { title ? this.getHeader(icon, title, dataElement.count() ) : null }
                <Collapse isOpen={!this.state.collapsed}>
                    {this.props.children}
                </Collapse>
                
            </Card>
        );
    }
}

const mapStateProps = (state: ApplicationState, ownProps: any) => ({
    containers: state.page.slots || {}
});

//export default connect(mapStateProps)(CardSection);
export const CardPanel = connect(mapStateProps)(CardSectionClass);