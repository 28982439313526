import * as React from 'react';
import { toast } from 'react-toastify';

export const Message = (props: any) => {
    const { icon, title, message } = props;
    //<h5><em className="fas fa-cubes mr-2"></em> My title</h5>

    return  <div>
        <h5>{icon} {title || message}</h5>
        {title && <p>{message}</p>}
   </div>
}

export const Notification = {
    warning: (message: string, title?:string) => {
        toast(<Message message={message} icon="⚠️" title={title} />, {
            position: "bottom-right"
        })
    },
    error: (message: string, title?:string) => {
        toast(<Message message={message} icon="❌" title={title} />, {
            position: "bottom-right"
        })
    },
    notify: (message: string, title?:string) => {
        toast(<Message message={message} icon="" title={title} />, {
            position: "bottom-right"
        })
    },
    success: (message: string, title?:string) => {
        toast(<Message message={message} icon="" title={title} />, {
            position: "bottom-right"
        })
    }
}