import { DataElement, IDataElement, DataElementStatus } from "../models";
import { store } from "@store/store";
import { Forms } from "@helpers/forms.helper";
import { SET_PAGE_SLOT, SET_GLOBAL } from "@store/actions/actions";

export class Store {
    constructor() { 
        //console.log("StoreModule: loaded");
    }

    private getUpdateableValues = (target: IDataElement | any, label: string = 'root') => {
        //console.log('getUpdateableValues', label);
        let items: Array<Number | string> = [];
        if (target === null || target === undefined) return items;
        for (let prop in target) {
            if (prop === 'lastUpdated') {
                items.push([label, prop, target[prop]].join(':'))
            } else if (prop === 'data') {
                items.push([label, prop, JSON.stringify(target[prop])].join(':'))
            } else if (target[prop] && target[prop].hasOwnProperty('lastUpdated')) { //&& target[prop].constructor.name === 'DataElement') {
                const list = this.getUpdateableValues(target[prop], [label,prop].join(':'))
                items.push(...list);
            } else {
                //console.log("activeform:updateablevalues", label,prop, JSON.stringify(target[prop]))
                //items.push([label, prop, JSON.stringify(target[prop])].join(":"))
            }
        }
        return items;
    }

    createStoreObject = (data: any = null, status: DataElementStatus = DataElementStatus.Default): IDataElement => {
        //if (status === DataElementStatus.Ready)
        const element = new DataElement();
        return element.setData(data, status).toObject();
    }

    createLoadingStoreObject = (data: any) => {
        return this.createStoreObject(data, DataElementStatus.Loading);
    }

    createUpdatingStoreObject = (data: any) => {
        return this.createStoreObject(data, DataElementStatus.Updating);
    }

    createSuccesfulStoreObject = (data: any) => {
        return this.createStoreObject(data, DataElementStatus.Ready);
    }
    /*
    dispatchAsync = (property: string, url: string, slot?: string) => {
        //store
        if (slot !== null && slot !== undefined && slot.trim().length > 0) property = [property, slot].join('$');
        //Http.dispatchAsync(url, property);
    }
    */
    hasChanges = (source: IDataElement | any, target: IDataElement | any): boolean => {
        source = this.isDataElement(source) ? source.getData() : source;
        target = this.isDataElement(target) ? target.getData() : target;
        const sourceValues = this.getUpdateableValues(source);
        const targetValues = this.getUpdateableValues(target);
        
        if (sourceValues.length !== targetValues.length) return true;

        for (let i = 0; i < sourceValues.length; i++) {
            const item1 = sourceValues[i];
            const item2 = targetValues[i];
            if (item1 !== item2) return true;
        }
        return JSON.stringify(source) !== JSON.stringify(target)

        //return false;
    }

    isReady = (source: IDataElement | any): boolean => {
        if (source === undefined || source == null) return false;
        if (source.hasOwnProperty("status"))
            return source.status == DataElementStatus.Ready
        return true
    }

    isDataElement(data:any):boolean {        
        if (data === undefined || data === null) return false;
        if (typeof(data) !== 'object' || Array.isArray(data)) return false;
        if (data.hasOwnProperty("data") && data.hasOwnProperty("lastUpdated") 
            && data.hasOwnProperty("isLoading") && data.hasOwnProperty("status") ) return true;
        return false;
    }

    getState(path: string, state?: any) {
        if (path === undefined) return {}
        if (path.indexOf("form$") === 0) {
            const parts = path.split('$');
            const element = Forms.getFormField(parts[1], parts[2], state)
            return element.input
        } 
        else {
            const parts = path.split(':');
            state = state === undefined ? store.getState() : state;
            for(let i=0; i<parts.length; i++) 
                state = state && state.hasOwnProperty(parts[i]) ? state[parts[i]] : [];
            return state;
        }
    }

    getStateDataElement(path: string, state?: any):DataElement | null {
        const storeObj = this.getState(path, state)
        return this.isDataElement(storeObj) ? new DataElement(storeObj) : null;
    }

    getActionStateDataElement(action: string, slot: string):DataElement | null {
        let path:string = "";
        let backupData: any = {};
        switch (action) {
            case SET_PAGE_SLOT:
                path = ["page","slots", slot].join(":");
                backupData = this.getState(path);
                break;
            case SET_GLOBAL:
                path = ["page", slot].join(":");
                backupData = this.getState(path);
                break;
        }
        return this.isDataElement(backupData) ? new DataElement(backupData) : null;
    }
}

export const Utils = new Store();
