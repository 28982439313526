import { store } from "@store/store"
import { PageFormDataState, SET_PAGE_FORM_ELEMENT } from "@store/actions/actions";
import FormValidator from "@components/Forms/FormValidator";
import { Application } from "@services/app.service";
import { DataElement } from "@models/store.models";
import DateTime from "./DateTime";

interface FormFieldValue {
    value: any
    type: string
    hasError: boolean
    validators: []
    source?: string
    modified: boolean
}

interface FormField {
    input: FormFieldValue,
    errors: any
}

export const Forms = {
    getForm,
    getFormField,
    getFormFieldValue,
    setFormFieldValue,
    hasErrors,
    getValue,
    getData,
    getAllData,
    getFieldName,
    FieldExists,
    validate,
    checkValidators,
    mapToDDLItem,
    setValue,
    setDDLValue,
    setAllData,
    resetUpdate,
    hasChanges
}

function getForm(formId: string, state?:any):PageFormDataState | undefined {
    
    if (state === undefined) state = store.getState().page;
    
    return state && state.forms && state.forms[formId];
}

function getFormField(formId: string, fieldId: string, state?:any):FormField {
    //fieldId = [formId,"[",fieldId,"]"].join("")
    fieldId = getFieldName(fieldId)
    fieldId = [formId,"[",fieldId,"]"].join("")
    
    const form = getForm(formId, state);
    const input = form ? form.inputs && form.inputs[fieldId] : null;
    const errors = form ? form.errors && form.errors[fieldId] : {};
    return { input, errors }
}

function getFormFieldValue(formId: string, fieldId: string, state?:any):any {
    fieldId = getFieldName(fieldId)
    fieldId = [formId,"[",fieldId,"]"].join("")
    
    const form = getForm(formId, state);
    const inputs = form ? form.inputs : {}
    //const errors = form ? form.errors && form.errors[fieldId] : {};
    let value = inputs.hasOwnProperty(fieldId) ? inputs[fieldId].value : "";
    
    //value = (typeof(value) === 'object') ? inputs[fieldId].value.value : value;
    return value;
}

function getFormFieldData(formId: string, fieldId: string, state?:any):any {
    //fieldId = [formId,"[",fieldId,"]"].join("")
    const form = getForm(formId, state);
    const inputs = form ? form.inputs : {}
    //const errors = form ? form.errors && form.errors[fieldId] : {};
    let value = inputs.hasOwnProperty(fieldId) ? inputs[fieldId].data : "";
    
    //value = (typeof(value) === 'object') ? inputs[fieldId].value.value : value;
    
    return value;
}

function setValue(formId: string, fieldId: string, value: any, dispatch:boolean = true) {
    fieldId = getFieldName(fieldId)
    const inputName = [formId,"[",fieldId,"]"].join("");
    const field = getFormField(formId, inputName);
    
    if (!field.input) return
    value =  typeof(value) === 'number' ? value : value || "";
    let data = value;
    if (field.input.type === 'ddl') {
        if (field.input.hasOwnProperty("source") && typeof(field.input.source) == 'string') {
            const entities = Application.Store.getState(field.input.source);
            
            const element = (!entities.hasOwnProperty("data") || Array.isArray(entities)) ? 
                            new DataElement().setData(entities) : 
                            new DataElement(entities);

            const val = value ? value.id || value.value || value : null
            //data = entities.find( (entity:any) => entity.id === val)
            
            const parts = fieldId.split(".")
            data = element.data != null ? element.data.find( (entity:any) => entity.id === val || entity[parts[parts.length - 1]] === val) : {}
        }
        if (data) value = mapToDDLItem(data)
    }
    
    const state = {
        inputs: {
            [inputName]: { value, data, modified: true  }
        }
    }
    
    if (dispatch)
        Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)

    return state;
}

function setDDLValue(formId: string, fieldId: string, value: any, mapFn:(item:any)=>{}, dispatch:boolean = true) {
    fieldId = getFieldName(fieldId)
    const inputName = [formId,"[",fieldId,"]"].join("");
    const field = getFormField(formId, inputName);
    
    if (!field.input) return
    value = value || ""
    let data = value;
    if (field.input.type === 'ddl') {
        if (field.input.hasOwnProperty("source") && typeof(field.input.source) == 'string') {
            const entities = Application.Store.getState(field.input.source);
            
            const element = (!entities.hasOwnProperty("data") || Array.isArray(entities)) ? 
                            new DataElement().setData(entities) : 
                            new DataElement(entities);

            const val = value ? value.id || value.value || value : null
            //data = entities.find( (entity:any) => entity.id === val)
            
            const parts = fieldId.split(".")
            data = element.data != null ? element.data.find( (entity:any) => entity.id === val || entity[parts[parts.length - 1]] === val) : {}
        }
        if (data) value = mapFn(data); //mapToDDLItem(data)
    }
    
    const state = {
        inputs: {
            [inputName]: { value, data, modified: true }
        }
    }
    
    if (dispatch)
        Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)

    return state;
}

function setFormFieldValue(formId: string, fieldId: string, value: any) {
    setValue(formId, fieldId, value, true);
    /*
    fieldId = getFieldName(fieldId)
    const inputName = [formId,"[",fieldId,"]"].join("");
    const field = getFormField(formId, inputName);
    
    if (!field.input) return
    value = value || ""
    let data = value;
    if (field.input.type === 'ddl') {
        if (field.input.hasOwnProperty("source") && typeof(field.input.source) == 'string') {
            const entities = Application.Store.getState(field.input.source);

            const element = (!entities.hasOwnProperty("data") || Array.isArray(entities)) ? 
                            new DataElement().setData(entities) : 
                            new DataElement(entities);

            const val = value ? value.id || value.value || value : null
            //data = entities.find( (entity:any) => entity.id === val)
            data = element.data != null ? element.data.find( (entity:any) => entity.id === val) : {}
        }
        if (data) value = mapToDDLItem(data)
    }
    
    const state = {
        inputs: {
            [inputName]: { value, data }
        }
    }
    
    Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
    */
}

function hasErrors(formId: string, fieldId?: string, method?: string):boolean {
    if (fieldId === undefined) {
        const form = getForm(formId);
        const inputs:any = form ? form.inputs : {}
        for(const inputName in inputs) {
            if (Forms.hasErrors(formId, inputName)) return true;
        }
    } else {
        const field = getFormField(formId, fieldId)
        if (method != undefined) {
            return field.errors ? field.errors[method] : false;
            //return field.errors[method];
        }
        return Forms.checkValidators(field.errors);
    }
    return false;
}

function checkValidators(validators: any):boolean {
    let hasErrors = false;
    for(const rule in validators)
        if (validators[rule]) hasErrors = true;
    return hasErrors;
}


function validate(formId: string) {
    const form = getForm(formId);
    const inputs:any = form ? form.inputs : {}
    
    for(const inputName in inputs) {
        let hasError = false;
        let result:any = [];

        if (inputs[inputName].type === 'ddl') {    
            for (const rule of inputs[inputName].validators) {
                let value = inputs[inputName].value;
                //if (typeof(inputs[inputName].value) === 'object') value = inputs[inputName].value.value;
                if (typeof(inputs[inputName].value) === 'object') value = (value && !Array.isArray(value) && String(value.value)) || "";
                const validation = FormValidator.validateValue(value, rule)
                result[rule] = validation[rule]
            }
            hasError = checkValidators(result)
        } else {
            const elements = document.getElementsByName(inputName)
            const field = elements.length > 0 ? elements[0] : undefined;
            result = FormValidator.validate(field)
            hasError = checkValidators(result)
        }
        const state = {
            inputs: {
                [inputName]: { ...inputs[inputName], hasError }
            },
            errors: {
                [inputName]: result
            }
        }
        Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
    }

    return !hasErrors(formId)
}

function getFieldName(inputName: string): string {
    const match = inputName.match(/\[([^)]+)\]/);
    const rtn = match && match.length > 0 ? match[1] : inputName
    
    return isNaN(Number(rtn)) ? rtn : inputName
    //return match && match.length > 0 ? match[1] : inputName
}

function getValue(formId: string, fieldId: string, state?:any):any {
    
    fieldId = getFieldName(fieldId)
    fieldId = [formId,"[",fieldId,"]"].join("")
    
    const form = getForm(formId, state);
    const inputs = form ? form.inputs : {}

    let value = undefined;
    if (inputs.hasOwnProperty(fieldId)) {
        value = inputs[fieldId].value //inputs[fieldId].type === 'ddl' ? inputs[fieldId].value.value : inputs[fieldId].value;
    }
    //let value = inputs.hasOwnProperty(fieldId) ? inputs[fieldId].data : undefined;

    return value;
}

function getData(formId: string, fieldId: string, state?:any):any {
    //console.log("getData", formId, fieldId, state);
    fieldId = getFieldName(fieldId)
    fieldId = [formId,"[",fieldId,"]"].join("")
    
    const form = getForm(formId, state);
    const inputs = form ? form.inputs : {}

    let value = inputs.hasOwnProperty(fieldId) ? inputs[fieldId].data : undefined;

    return value;
}

function getAllData(formId: string, state?:any): any[] {
    const form = getForm(formId, state);
    const inputs = form ? form.inputs : {};
    let values:any = {}
    for(const field in inputs) {
        values[getFieldName(field)] = inputs[field].data
    }
    return values;
}
/*
function setData(formId: string, fieldId: string, entity:any): void {
    const form = getForm(formId);
    if (form === undefined) return
    let state = { inputs: {} };

    const parts = fieldId.split('.');
        
    let value = { ...entity };
    for(let i=0; i<parts.length; i++) {
        value = value && value.hasOwnProperty(parts[i]) ? value[parts[i]] : null;
    }
    console.log("activeform:set-single-value", fieldId, value)
    const inputState = setValue(formId, fieldId, value, false)
    state = {
        ...state,
        inputs: {...state.inputs, ...inputState && inputState.inputs }
    }

    console.log("activeform:map-end", state)
    Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
}
*/
function setAllData(formId: string, data:any, inputProps:any = {}): void {
    const form = getForm(formId);
    if (form === undefined) return
    let state = { inputs: {} };
    //console.log("activeform:setalldate",data, form)
    for (const input in form.inputs) {
        const fieldName = getFieldName(input);
        const parts = fieldName.split('_');
        
        let value = { ...data };
        for(let i=0; i<parts.length; i++) {
            const arrIndex = parts[i].match(/\[(.*?)\]/);
            if (arrIndex!=null) {
                //parts[i].match(/\[([^[]*)\]/g);
                const index = Number(arrIndex[1])
                let prop = parts[i].replace(arrIndex[0], "");
                //console.log(fieldName, index, prop)
                value = value && value.hasOwnProperty(prop) ? value[prop][index] : null;
            } else {
                value = value && value.hasOwnProperty(parts[i]) ? value[parts[i]] : null;
            }
        }
        
        let inputState;
        if (inputProps.hasOwnProperty(fieldName)) {
            inputState = inputProps[fieldName].type === 'ddl' ? setDDLValue(formId, fieldName, value, (item) => mapToDDLItem(item, inputProps[fieldName]) ) : setValue(formId, fieldName, value, true)
        } else {
            inputState = setValue(formId, fieldName, value, true)
        }
        
        state = {
            ...state,
            inputs: {...state.inputs, ...inputState && inputState.inputs }
        }
    }
    //console.log("activeform:map-end", state)
    //Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
}

function FieldExists(formId: string, fieldId: string):boolean {
    const form = getForm(formId);
    return form ? form.inputs && form.inputs.hasOwnProperty(fieldId) : false;
}

/*
function mapToDDLItem(data: any) {
    console.log("ddl:maptoddlitem")
    const value = data.id || data.value;
    const tag = data.clave || data.tag;
    const label = data.nombre || data.label;
    const color = data.color || "#5d9cec";

    return { label, value, tag, color }   
}
*/

function mapToDDLItem(item: any, props: any = {}) {
    const { keyColumn, tagColumn, labelColumn, colorColumn, defaultKeyColor } = props;
    //console.log("ddl:maptoddlitem")
    const value = keyColumn && item.hasOwnProperty(keyColumn) ? item[keyColumn] : (item.id || item.value);
    const tag = tagColumn && item.hasOwnProperty(tagColumn) ? item[tagColumn] : (item.clave || item.tag || item.id);
    const label = labelColumn && item.hasOwnProperty(labelColumn) ? item[labelColumn] : item.nombre || item.label;
    const color = colorColumn && item.hasOwnProperty(colorColumn) ? item[colorColumn] : item.color || defaultKeyColor || "#5d9cec";
    return { label, value, tag, color }   
}

function resetUpdate(formId: string, fieldId?: string) {
    if (fieldId === undefined) {
        const form = getForm(formId);
        const inputs:any = form ? form.inputs : {}
        for(const inputName in inputs) {
            const state = {
                inputs: {
                    [inputName]: { modified: false  }
                }
            }
            Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
        }
    } else {
        fieldId = getFieldName(fieldId)
        fieldId = [formId,"[",fieldId,"]"].join("")
        const state = {
            inputs: {
                [fieldId]: { modified: false  }
            }
        }
        Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
    }
    /*
    const form = getForm(formId);
    if (form === undefined) return
    let state = { inputs: {} };
    
    for (const inputName in form.inputs) {
        const state = {
            inputs: {
                [inputName]: { modified: false  }
            }
        }
        
        Application.dispatch(SET_PAGE_FORM_ELEMENT, {...state}, formId)
    }
    */
}

function hasChanges(formId: string, fieldId?: string):boolean {
    if (fieldId === undefined) {
        const form = getForm(formId);
        const inputs:any = form ? form.inputs : {}
        for(const inputName in inputs) {
            if (Forms.hasChanges(formId, inputName)) return true;
        }
    } else {
        const field = getFormField(formId, fieldId)
        return field.input.modified;
    }
    return false;
}

export class FormData {
    private _data: any = {}
    private _formId:string = ""
    private _form:PageFormDataState | undefined
    private _inputs: {[key:string] : any}

    constructor(formId:string) {
        this._formId = formId
        this._form = getForm(formId)
        this._inputs = this._form ? this._form.inputs : {};
    }

    private fieldExists(fieldName: string) {
        if (!this._inputs.hasOwnProperty(fieldName)) {
            console.warn(`El formulario ${this._formId} no contiene el atributo ${fieldName}` )
            return false;
        }
        return true
    }

    add(fieldId: string) {
        var fieldName = getFieldName(fieldId);
        if (this.fieldExists(fieldName)) this._data[fieldName] = this._inputs[fieldName].data
        return this;
    }
    
    addObject(fieldId: string) {
        var fieldName = getFieldName(fieldId);
        //if (this.fieldExists(fieldName)) this._data[fieldName] = JSON.parse(JSON.stringify(this._inputs[fieldName].data))
        if (this.fieldExists(fieldName)) {
            if (typeof(this._inputs[fieldName].data) === 'object')
                this._data[fieldName] = { ...this._inputs[fieldName].data }
            else
                this._data[fieldName] = this._inputs[fieldName].data
        }
        return this;
    }

    addString(fieldId: string) {
        var fieldName = getFieldName(fieldId);
        if (this.fieldExists(fieldName)) this._data[fieldName] = this._inputs[fieldName].data.toString();
        return this;
    }

    addDateTime(fieldId: string) {
        var fieldName = getFieldName(fieldId);
        if (this.fieldExists(fieldName))
            this._data[fieldName] =  new DateTime(this._inputs[fieldName].data).format()

        return this;
    }

    addDate(fieldId: string) {
        return this.add(fieldId);
    }

    addTime(fieldId: string) {
        return this.add(fieldId);
    }

    toObject() {
        return {...this._data}
    }
}