import React from 'react';
import { Link } from 'react-router-dom';

type ErrorProps  =  {
    recover: () => void
    //report: typeof reportError
    error: Error | undefined
  }

export default class ErrorPage extends React.Component<ErrorProps> {
    render() {
        const { recover } = this.props
        return (
            <div className="abs-center wd-xl">
            <div className="text-center mb-4">
                <div className="mb-3 mt-3">
                    <em className="fa fa-wrench fa-5x text-muted"></em>
                </div>
                
                <p className="lead m-0">Oh! Algo salió mal :(</p>
                <p>No se preocupe, ahora estamos comprobando esto.</p>
                <p>Mientras tanto, pruebe uno de los enlaces a continuación o vuelva en un momento</p>
            </div>
            <ul className="list-inline text-center text-sm mb-4">
                <li className="list-inline-item">
                    <Link to={process.env.REACT_APP_FOLDER || "/"} className="text-muted" onClick={recover}>Ir a la aplicación</Link>
                </li>
                <li className="text-muted list-inline-item">|</li>
                <li className="list-inline-item">
                    <Link to="login" className="text-muted">Iniciar sesión</Link>
                </li>
            </ul>
            <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>{process.env.REACT_APP_VERSION}</span>
                <span className="mx-2">-</span>
                <span>{process.env.REACT_APP_NAME}</span>
                <br/>
                <span></span>
            </div>
        </div>
        )
    }
}
