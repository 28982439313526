export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
    id?: string;
    collapsible?: boolean
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        id: "home",
        name: 'Inicio',
        path: '/home',
        icon: 'icon-grid',
        translate: 'sidebar.nav.HOME'
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'icon-chart',
    },
    {
        name: 'Tareas',
        path: '/tasks',
        icon: 'icon-calendar',
    },
    {
        heading: 'Operación'
    },
    {
        id: 'porRecibir',
        name: 'Por recibir',
        path: '/arrivals',
        icon: 'icon-grid',
    },
    {
        id: 'enProceso',
        name: 'En proceso',
        path: '/departures',
        icon: 'icon-grid',
    },
    {
        id: 'porFacturar',
        name: 'Por facturar',
        path: '/invoice-pending',
        icon: 'icon-grid',
    },
    {
        heading: 'Administrar'
    },
    {
        name: 'Ordenes servicio',
        path: '/orders',
        icon: 'icon-list',        
    },/*
    {
        name: 'Subnivel 1',
        icon: 'icon-list',
        submenu: [{
                name: 'Dashboard v1',
                path: '/dashboardv1'
            },
            {
                name: 'Dashboard v2',
                path: '/dashboardv2'
            },
            {
                name: 'Subnivel 2',
                icon: 'icon-list',
                path: '',
                submenu: [
                    {
                        name: 'S2 Dashboard v1',
                        path: '/dashboardv1'
                    },
                    {
                        name: 'S2 Dashboard v2',
                        path: '/dashboardv2'
                    },
                    {
                        name: 'Subnivelx 3',
                        icon: 'icon-list',
                        path: '',
                        collapsible: true,
                        submenu: [
                            {
                                name: 'S3 Dashboard v1',
                                path: '/dashboardv1'
                            },
                            {
                                name: 'S3 Dashboard v2',
                                path: '/dashboardv2'
                            }      
                        ]
                    }      
                ]
            },
            
            {
                name: 'Dashboard v3',
                path: '/dashboardv3'
            }
        ]
    },*/
    {
        name: 'Ordenes canceladas',
        path: '/canceled',
        icon: 'icon-list',
    },
    {
        name: 'Pagos',
        path: '/payments',
        icon: 'icon-credit-card',
    },
    {
        name: 'Configuración',
        path: '/settings',
        icon: 'icon-settings',
    }
];

export default Menu;
