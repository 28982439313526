import { http } from '@helpers/helpers.module';
import { ApplicationUser, ApiResultCodes, QueryParameterNames } from '../models';
import { Application } from './app.service';
import { SET_GLOBAL, LOGIN, LOGOUT, REFRESH_TOKEN, APP_DATA } from '../store/actions/actions';
import i18n from "i18next";
import messagingService from './messaging.service';

interface AuthorizeSubscription {
    subscription: number
    callback: () => void
}

class AuthenticationService {
    //_callbacks: Array<AuthorizeSubscription> = [];
    //_nextSubscriptionId = 0;
    _user: ApplicationUser | null = {
        id: 0,
        firstName: "",
        username: "",
        groupName: ""
    };
    _isAuthenticated = false;
    _intervalHandler:any;
    _messaging = new messagingService();

    isAuthenticated(): boolean {
        const user = this.getUser();
        return !!user;
    }

    getUser() {
        if (this._user) {
            return this._user;
        }
        return null;
        /*
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.profile;
        */
    }

    getAccessToken() {
        //await this.ensureUserManagerInitialized();
        //const user = await this.userManager.getUser();
        //return user && user.access_token;
        return this._user && this._user.token;
    }

    getRefreshToken() {
        //await this.ensureUserManagerInitialized();
        //const user = await this.userManager.getUser();
        //return user && user.access_token;
        return this._user && this._user.refreshToken;
    }

    async recover(username: string) {
        return http.post("accounts/recover", { username }).then(response => {
            if (response.code === ApiResultCodes.Ok)
                return true; //response.data[0]
            return false;
        })
    }

    async changePassword(currentPassword:string, newPassword: string, repeatPassword: string) {
        return http.post("accounts/change-password", { password: newPassword, password_repeat: repeatPassword, password_old: currentPassword }).then(response => {
            if (response.code === ApiResultCodes.Ok)
                return true
            return false;
        })
    }

    signIn(username: string, password: string) {
        Application.dispatchPost(
            LOGIN,
            "accounts/authenticate",
            { email : username, password },
            'user',
            (response) => {
                if (response.code === ApiResultCodes.Ok) {
                    //this.completeSignIn(response.data[0]);
                }
            }
        );
    }

    completeSignIn(user: any, loadConfig:boolean = false) {
        this.updateState(user);
        if (this._isAuthenticated && loadConfig) {
            this.getConfiguration("");
        }
    }

    signOut() {
        Application.dispatch(LOGOUT)
        console.log("Auth.Service:signOut")
        this.updateState(null);
        clearInterval(this._intervalHandler);
    }

    logOut() {
        this.signOut();
        const redirectUrl = `/login?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        Application.navigateToPath(redirectUrl);
    }

    completeSignOut(url: string) {
        /*
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager.signoutCallback(url);
            this.updateState(null);
            return this.success(response && response.data);
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
        */
    }

    updateState(user: any) {
        this._user = user;
        this._isAuthenticated = !!this._user;
    }

    async refreshToken() {
        const token = this.getAccessToken();
        const refreshToken = this.getRefreshToken();

        return await http.post('accounts/renewToken', { token, refreshToken })
            .then(response => {
                if (response.code === ApiResultCodes.Ok) {
                    Application.dispatch(REFRESH_TOKEN, {
                        token: response.data[0]['token'],
                        refreshToken: response.data[0]['refreshToken']
                    })
                    this.updateState({
                        ...this._user,
                        token: response.data[0]['token'],
                        refreshToken: response.data[0]['refreshToken']
                    })
                }
                return response;
            });
    }

   async getConfiguration(returnUrl: string) {
        if (!this.isAuthenticated()) return
        Application.dispatchToSlot(SET_GLOBAL, "appready", false);
        //const result = await http.get('accounts/getApplicationData')
        const result = {
            code: 0,
            data: [
                { 
                    timezone: { id: 1, clave: "America/Mexico_City", nombre: "Hora del centro de México"},
                    language: {clave: "es-MX"} }
            ]
        }
        if (result.code === ApiResultCodes.Ok) {
            Application.dispatch(APP_DATA, { target: 'app-data', data: { data: [ result.data[0]] }});
            i18n.changeLanguage(result.data[0].language.clave)
            this._messaging.initialize();
            this.getNotifications();
            this._intervalHandler = setInterval( () => this.getNotifications(), 5 * 60 * 1000 );
        }
        
        Application.dispatchToSlot(SET_GLOBAL, "appready", true);
    }

    async getNotifications() {
        const result = await http.get('accounts/getNotifications');
        if (result.code === ApiResultCodes.Ok) {
            Application.dispatchToSlot(SET_GLOBAL, "notifications", result.data);
        }
    }
}

export const Authentication = new AuthenticationService();

//export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
