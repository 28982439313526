  
  
  export interface SubastaView {
    id: number;
    activa: string;
    clave: string;
    nombre: string;
    opcionesIVA: Impuesto[];
    descripcion: string;
    responsable: string;
    fraccionamiento: string;
    fraccionamiento2: string;
    plaza: string;
    compania: string;
    idTipoObra: number;
    tipoObra: string;
    idEstatus: number;
    estatus: string;
    eventos: Eventos;
    documentosPublicos: Documento[];
    participantesVisibles: boolean;
    participantes: Concursante[];
    accesoContratista: boolean;
    cargaGraficas: boolean;
    actualizarAutomatico: boolean;
    secciones: SeccionesView;
    menu: PanelAcciones;
    presupuesto: Oferta[];
    resumen: ResumenView;
    puja: PujaView;
    notificaciones: Notificacion[]
  }

  export interface Impuesto {
    label: string
    value: number
  }

  export interface Notificacion {
    mensaje: string
    creado: string
  }
    
  export interface ResumenView {
    acceso: boolean;
    dataOfertaInicial: DataOferta;
    dataAnticipos: DataAnticipos;
    dataOfertaActual: DataOferta;
    dataDuracionObra: DataDuracionObra;
  }
  
  export interface DataDuracionObra {
    labels: string[];
    datasets: ChartDataset[];
    max: number;
    ruba: number;
  }
  
  export interface DataAnticipos {
    labels: string[];
    datasets: ChartDatasetAnticipos[];
    max: number;
    ruba: string;
  }
  
  export interface ChartDatasetAnticipos {
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    yAxisID: string;
    data: number[];
  }
  
  export interface DataOferta {
    labels: string[];
    datasets: ChartDataset[];
  }
  
  export interface ChartDataset {
    label: string;
    iva?: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    data: number[];
  }
  /*
  export interface PresupuestoView {
    iniciaObra?: any;
    finalizaObra?: any;
    anticipo: string;
    consecutivo:number;
    detalle: OfertaPartida[];
    disabled: boolean;
    modificar: boolean;
  }
  
  export interface PartidaPresupuesto extends TreeViewItem {
    orden_cedula: number;
    descripcion_nivel: string;
    id_subasta: number;
    orden: number;
    nivel: number;
    ind_nivel: number;
    concepto: string;
    id_unidad: string;
    cantidad: string;
    precio: string;
    autorizado: string;
    id_oferta: string;
    consecutivo: string;
    simbolo: string;
    frente: string;
  }
  */
  export interface SeccionesView {
    presupuesto: boolean;
    puja: boolean;
    resumen: boolean;
    ganador: boolean;
  }
  
  export interface Concursante {
    idUsuario: number;
    nombre: string;
    ganador: number;
    email: string;
    numpro: number;
    enviado: boolean;
    observaciones?: string;
    aceptoInvitacion: boolean;
    documentosValidos: boolean;
    documentosValidados: boolean;
    estatus: string;
    online: boolean;
    descargarDocumentosTecnicos: string;
    descargarDocumentosEconomicos: string;
    documentos: DocumentosContratista
    permiteValidarDocumentos: boolean
    ofertas: Oferta[]
    ofertaActual: number
    ofertaAutorizada:number,
    iva?: number
  }

  export interface DocumentosContratista {
    tecnicos: Documento[]
    economicos: Documento[]
    avanceCargaTecnicos: number
    avanceCargaEconomicos: number
  }
  
  export interface Documento {
    id: number;
    nombre: string;
    cargado?: number
    valido?: number
  }

  export interface Oferta {
    consecutivo: number
    inicia?: string
    termina?: string
    duracion: number
    porcAnticipo: number
    porcIva: number
    documentoIva?: string
    costoInicial: number
    costo: number
    porcReduccion: number
    diffReduccion: number
    comentarios?: string
    autorizada: boolean
    partidas: OfertaPartida[]
    historialComentarios: string[]
    disabled?: boolean;
    modificar?: boolean;
  }

  export interface OfertaPartida extends TreeViewItem {
    orden_cedula: number
    descripcion_nivel: string
    id_subasta: number
    orden: number
    nivel: number
    ind_nivel: number
    concepto: string
    id_unidad: number
    cantidad: number
    precio: number
    autorizado: number
    id_oferta: number
    consecutivo: number
    simbolo: string
    frente: string
  }
  
  export interface Eventos {
    fechaElaboracion: string;
    fechaConfirmacion: string;
    fechaCierreForo: string;
    fechaCargaPresupuesto: string;
    fechaIniciaPuja: string;
    fechaFinalizaPuja: string;
    fechaAperturaProposiciones: string;
    fechaDesicion: string;
  }

  export interface PanelAcciones {
    enviarInvitacion: MenuAccion;
    participantes: MenuAccion;
    preguntas: MenuAccion;
    presupuesto: MenuAccion;
    puja: MenuAccion;
    reporteGeneral: MenuAccion;
    reporteEjecutivo: MenuAccion;
    ganador: MenuAccion;
    decision: MenuAccion;
  }
  
  export interface MenuAccion {
    activo: boolean;
    url: string;
  }

  export interface PujaView {
    accesoConsultar: boolean
    permiteActuaizar: boolean;
  }
  
  export interface ParticipanteOferta {
    nombre: string;
    online: boolean;
    inicia: string;
    termina: string;
    duracion: string;
    anticipo: string;
    ofertaInicial: number;
    ultimaOferta: number;
    porcReduccion: number;
    diffReduccion: number;
  }

  export interface TreeViewItem {
    handle: number
    parent?: number
    hasChildren?:boolean
    label: string
    data: any
    expanded: boolean
    level: number
    children: TreeViewItem[]
    icon: string
  }

  export enum EstatusSubasta {
    Registro = 1,
    Convocatoria = 2,
    Desierta = 3,
    AltaPropuestas = 4,
    Puja = 5,
    Anulada = 6,
    Deliberacion = 7,
    Fallo = 8 
}