import { AppSessionAction, AppSessionState, LOGIN, LOGOUT, REFRESH_TOKEN } from "../actions/actions"

const initialSessionState: AppSessionState = {
    isLoading: false,
    lastUpdated: 0,
}

const userReducer = (state: any, action: AppSessionAction) => {
    switch (action.type) {
        case REFRESH_TOKEN:
            return {
                ...state,
                token: action.token,
                refreshToken: action.refreshToken
            }
        default:
            return state;
    }
}

const sessionReducer = (state = initialSessionState, action: AppSessionAction): AppSessionState => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                [action.target]: action.data.data && action.data.data[0], //action.data.data !== null ? action.data.data[0] : null,
                isLoading: action.data.isLoading,
                lastUpdated: action.data.lastUpdated
            }
        case LOGOUT:
            return {
                ...state,
                user: undefined,
                isLoading: false,
                lastUpdated: 0,
            }
        case REFRESH_TOKEN:
            return {
                ...state,
                user: userReducer(state.user, action)
            }
        default:
            return state;
    }
}

export default sessionReducer;
