import { http } from '@helpers/helpers.module';
import firebase from 'firebase/app';
import 'firebase/messaging';

export default class {
    initialize() {
        const firebaseConfig = {
            apiKey: "AIzaSyC9BdWZl6f3k4bv3iHWbB6MbfbrTu262OY",
            authDomain: "washapp-219321.firebaseapp.com",
            databaseURL: "https://washapp-219321.firebaseio.com",
            projectId: "washapp-219321",
            storageBucket: "washapp-219321.appspot.com",
            messagingSenderId: "527311771103",
            appId: "1:527311771103:web:5e36030695b4a0d047206a"
        };
        
        firebase.initializeApp(firebaseConfig);
        
        if (!firebase.messaging.isSupported()) return;

        const messaging = firebase.messaging();
        
        messaging.requestPermission()
        .then( () =>  messaging.getToken())
        .then( token => http.post('accounts/register-notifications', {token}))
        .catch( err => console.log(err));
        
        messaging.onMessage( payload => {
            //Notification.notify(payload.notification.body, payload.notification.title)
            if ("Notification" in window) {
                const message = new window.Notification(payload.notification.title, { icon: '/icon.png' })
                message.onclick = function(event:any)  {
                    this.close();
                }
            } else {
                navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope')
                .then(registration => registration && registration.showNotification(payload.notification.title, { icon: '/icon.png' }));
            }
        })
    }
}