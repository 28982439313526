import { http } from "@helpers/http.helper";
import { IDataElement, ApplicationData } from "@models/index";

export const SET_GLOBAL = 'global-prop';
export const APP_DATA = 'APP_DATA';
export const SET_ENTITY = 'SET_ENTITY';
export const CLEAR_GLOBAL = 'CLEAR_GLOBAL';
export const APP_ERROR = 'APP_ERROR'

interface GlobalDefaultAction {
    type: typeof SET_GLOBAL
    target: string
    payload: any
    data?: any
}

interface GlobalAppDataAction {
    type: typeof APP_DATA
    data: IDataElement
}

interface GlobalEntityAction {
    type: typeof SET_ENTITY
    target: string,
    data: any
}

interface GlobalClearAction {
    type: typeof CLEAR_GLOBAL
}

interface ApplicationErrorAction {
    type: typeof APP_ERROR
    payload: any
}

export type GlobalAction = GlobalDefaultAction | GlobalAppDataAction | GlobalEntityAction | GlobalClearAction | ApplicationErrorAction

export interface GlobalSectionState {
    entities: any;
    //entity: IDataElement;
    currentIndex: number,
    form: any;
}

export interface GlobalAppStateEntitiesData {
    [entityName: string]: any
}

export interface GlobalAppStateData {
    data: ApplicationData
    entities: GlobalAppStateEntitiesData
    isLoading: boolean
    lastUpdated: number
}

export interface GlobalState {
    [index: string]: any,
    application: GlobalAppStateData 
}

export function reportError(error:any):GlobalAction {
    //http.post("accounts/log-error", error);
    return { type: APP_ERROR, payload: error };
}

