import settings from './settings.middleware'
import themes from './themes.middleware';
import api from './api.middleware';
import signalR  from './signalr.middleware';

export default [
    settings,
    themes,
    api
    //signalR //Deshabilitado signalR
]

