import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares'

import { SettingState } from './actions/settings.actions';
import { ThemeState } from './actions/themes.actions';
import { ApiState } from './actions/api.actions'
import { GlobalState, AppSessionState, PageState } from './actions/actions';

import { updateTheme } from './middlewares/themes.middleware';

import { persistedState, saveState } from './persisted.store';
//import { SignalRAction, signalrRegisterCommands, SIGNALR_GET_CONNECTIONID } from './middlewares/signalr.middleware';

export interface ApplicationState {
    settings: SettingState,
    theme: ThemeState,
    api: ApiState,
    global: GlobalState,
    session: AppSessionState,
    page: PageState
}

function configureStore() {

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        //console.log(process.env.NODE_ENV)
        if (process.env.NODE_ENV === 'development' )
            enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        reducers,
        persistedState, // second argument overrides the initial state
        compose(applyMiddleware(...middlewares), ...enhancers)
    );

    // add a listener that will be invoked on any state change
    store.subscribe(() => {
        saveState(store.getState());
    });

    // Update the initial theme
    updateTheme(store.getState())
    //windowIfDefined.store = store;

    return store;

}

export const store = configureStore();
/*
signalrRegisterCommands(store, () => {
    console.log('SignalR Connected');
    const action: SignalRAction = {
        type: SIGNALR_GET_CONNECTIONID,
        callback:() => console.log('SignalR user added to group')
    }
    store.dispatch(action);
    store.dispatch({ type: SIGNALR_GET_CONNECTIONID, callback: () => console.log('SignalR user added to group') });
});
*/