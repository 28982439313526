import { IDataElement, ApiResult } from "../../models";
import Axios, { CancelToken } from "axios";

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ACCESS_DENIED = "ACCESS_DENIED";


export interface ApiState {
    //[index: string]: IDataElement | [] | any
    //result: ApiResult
    [action: string]: ApiResult
}

interface ApiDefaultAction {
    type: typeof API_SUCCESS | typeof API_START | typeof API_END | typeof API_ERROR;
    label: string,
    payload?: any;
}

interface ApiHttpOptions {
    url: string;
    method: string;
    data: any;
    accessToken: string | null;
    onSuccess?: any;
    onFailure: any;
    cancelToken?:CancelToken;
    headersOverride?: boolean;
    actionType: string;
    slot?: string;
}

interface ApiRequestAction {
    type: typeof API,
    payload: ApiHttpOptions
}

export type ApiAction = ApiDefaultAction | ApiRequestAction;

export const apiRequestCallback = (action: string, target: string, dataElement: IDataElement) => ({
    type: action,
    target,
    data: dataElement
})

export const apiStart = (label: string) => ({
    type: API_START,
    label
});

export const apiEnd = (label: string) => ({
    type: API_END,
    label
});

export const apiError = (label: string, error: any) => ({
    type: API_ERROR,
    label,
    payload : error
});

export const apiSuccess = (label: string, response: any) => ({
    type: API_SUCCESS,
    label,
    payload: response
});

export const accessDenied = (url: string) => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = (response:any) => { },
    onFailure = (error:any) => { },
    cancelToken = Axios.CancelToken.source().token,
    actionType = 'global',
    slot = 'default',
    headersOverride = false
}): ApiRequestAction {
    
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            headersOverride,
            onSuccess,
            onFailure,
            cancelToken,
            actionType,
            slot,
        }
    };
}

