import { Notification } from "@components/common.module";
import axios, { AxiosResponse, AxiosError } from "axios"
import { ApiResult, ApiResultCodes } from "../models";
import { Authentication } from "../services";
//import { history } from "./history";

export const http = {
    request,
    get,
    post,
    put,
    del,
    postForm
}

export enum HttpMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE'
}

const BASE_URL = process.env.REACT_APP_API_URL //'https://localhost:44300/api/';

function request(method: HttpMethod, url: string, body: any, contentType:string = "application/json"): Promise<ApiResult>  {
    let relativeUrl = (!url.startsWith('https:') && !url.startsWith('http:'));
    //ajaxConfig.headers = { "X-EK-PAGE-ID": window["__page_id__"] };
    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    const accessToken = Authentication.getAccessToken();
    // axios default configs
    axios.defaults.baseURL = ""; //process.env.REACT_APP_BASE_URL || "";
    axios.defaults.headers.common["Content-Type"] = contentType;
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    //if (relativeUrl) url = `${BASE_URL}${url}`;

    return axios
        .request({
            url: relativeUrl ? `${BASE_URL}${url}` : url,
            method,
            [dataOrParams]: body
        })
        .then((response: AxiosResponse) => {
            let result:ApiResult = {
                code: ApiResultCodes.Ok,
                data: []
            };
            if (relativeUrl) {
                result = response.data;
                switch (result.code) {
                    case ApiResultCodes.Information:
                        Notification.notify(result.message || "");
                        break;
                    case ApiResultCodes.Error:
                        Notification.error(result.message || "");
                        break;
                    case ApiResultCodes.NotFound:
                    case ApiResultCodes.Warning:
                        Notification.warning(result.message || "");
                        break;
                    default:
                        break;
                }
            } else {
                result.data = [response.data];
            }
            

            return new Promise((resolve, reject) => {
                if (result)
                    resolve(result);
                reject("Error al procesar la solicitud AxiosResponse");
            });
        })
        .catch( async (error: AxiosError) => {
            const response = error.response || { status: 0, headers: {} };
            if (response.status === 401) {
                if (response.headers["token-expired"]) {
                    const refreshToken = await Authentication.refreshToken();
                    if (refreshToken.code === ApiResultCodes.Ok) {
                        return request(method, url, body);
                    }
                }
                //Authentication.logOut();
                Authentication.signOut();
            }
            if (response.status === 403) {
                Notification.warning("Acceso denegado al recurso que solicita "+error.message);
            } else if (response.status === 404) {
                Notification.warning("Recurso no encontrado "+error.message);
            } else {
                Notification.warning(error.message);
            }
            let result:ApiResult = {
                code: ApiResultCodes.Error,
                message: error.message,
                data: []
            };

            return new Promise((resolve, reject) => {
                if (result)
                    resolve(result);
                reject("Error al procesar la solicitud AxiosResponse");
            });

        })
        .then((response: any) => {
            //console.log("http:Helper:reponse2", response);
            return new Promise((resolve, reject) => {
                if (response)
                    resolve(response);
                reject("Error al procesar la solicitud AxiosResponse");
            });
        });
}


function get(url: string) { return request(HttpMethod.GET, url, null) }
function post(url: string, data: any) { return request(HttpMethod.POST, url, data) }
function postForm(url: string, data: any) { return request(HttpMethod.POST, url, data, "multipart/form-data") }
function put(url: string, data: any) { return request(HttpMethod.PUT, url, data) }
function del(url: string) { return request(HttpMethod.DELETE, url, null) }