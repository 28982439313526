import moment, { unitOfTime } from 'moment-timezone'
import 'moment/locale/es-us';
import { Application } from '@services/index';


/**
 * Updates every second the content of the element
 * with the current time formmated
 */
export default class DateTime  {

    value:moment.Moment | null;

    static create() {
        //const a = moment.tz(Application.getTimezone().clave);
        //const b = a.format("YYYY-MM-DD HH:mm:ss")
        return new DateTime(moment.tz(Application.getTimezone().clave).format("YYYY-MM-DD HH:mm:ss"));
    }

    toDateString() {
        return this.value && this.value.format("YYYY-MM-DD HH:mm:ss");
    }

    toDateStringZ() {
        const z = this.toUserTz();
        return z.value ? z.value.format("YYYY-MM-DD HH:mm:ss") : "";
    }  

    constructor(date?: string | Date | undefined, tz?: string) {
        moment.locale("es-us");
        if (date === null) 
            this.value = null //date = new Date()
        else
            this.value = tz ? moment.tz(date, tz) : moment.tz(date, Application.getTimezone().clave) ;
        /*
        if (date === undefined || date === null) {
            this.value = moment();
        }
        
        if (typeof(date) === 'string') this.value = moment(date);
        if (typeof(date) === 'object' && date.constructor.name === 'Date') this.value = moment()
        const a = moment(new Date()).format("dd/mm/yyy")
        moment()
        moment("2010-02-13T00:00:00")
        console.log("moment",moment(new Date()).format(), moment("2010-02-13T00:00:00").format("DD/MM/YYYY hh:mm:ss a"));
        */
    }

    now(tz?:string):DateTime {
        this.value = tz ? moment.tz(tz) : moment.tz(Application.getTimezone().clave);
        //console.log('timezones',moment.tz.names().filter(value => value.startsWith("America")));
        return this;
    }

    moment() {
        //moment().tz("sd", "")
        return this.value ;
    }

    toTz(timeZone:string) {
        this.value = this.value ? this.value.clone().tz(timeZone) : this.value;
        return this;
    }

    toUserTz() {
        //const tz = Application.getUserTimezone().clave //this.getUserTimezone();
        const tz = moment.tz.guess(true);
        //console.log("toUserTz", tz)
        return this.toTz(tz);
    }

    toAppTz() {
        const tz = Application.getTimezone().clave //this.getAppTimezone();
        return this.toTz(tz);
    }

    format():string {
        return this.value? this.value.format().slice(0, -6) : "";
    }

    formatDateTime():string {
        return this.value ? this.value.format("DD/MM/YYYY hh:mm A") : "";
    }

    formatDate():string {
        return this.value? this.value.format("DD/MM/YYYY") : "";
    }

    formatShortDate() {
        return this.value ? this.value.format("MMM DD, YYYY") : "";
    }

    formatTime():string {
        return this.value ? this.value.format("hh:mm A") : "";
    }

    formatTimeZ():string {
        const z = this.toUserTz();
        return z.value ? z.value.format("hh:mm A") : "";        
    }

    formatZ():string {
        const z = this.toUserTz();
        return z.value ? z.value.format().slice(0, -6) : "";
    }

    formatDateTimeZ():string {
        const z = this.toUserTz();
        return z.value ? z.value.format("DD/MM/YYYY hh:mm A") : "";
    }

    formatDateZ():string {
        const z = this.toUserTz();
        return z.value ? z.value.format("DD/MM/YYYY") : "";
    }

    formatShortDateZ() {
        const z = this.toUserTz();
        return z.value ? z.value.format("MMM DD, YYYY") : "";
    }

    formatLongDateTimeZ() {
        const z = this.toUserTz();
        return z.value ? z.value.format("DD MMM, YYYY hh:mm A") : "";
    }

    fromNowZ(): string {
        const z = this.toUserTz();
        return z.value ? z.value.fromNow() : "";
    }

    dateDiff(date: DateTime, unitOFtime: unitOfTime.Diff):number {
        if (this.value == null || date.value == null) return 0;
        return this.value.diff(date.value, unitOFtime);
    }

    add(amount: number, unit: unitOfTime.Base):DateTime {
        if (this.value == null) return this;
        this.value.add(amount, unit);
        return this;
    }
}
