import { IDataElement } from "../../models";

export const INIT_PAGE = 'PAGE_INIT_PAGE';
export const SET_PAGE_VIEW = 'SET_PAGE_VIEW';
export const LOAD_SECTION = 'PAGE_LOAD_SECTION'
export const SET_PAGE_SLOT = 'PAGE_SET_SLOT'
export const SET_PAGE_FORM = 'SET_PAGE_FORM'
export const SET_PAGE_FORM_ELEMENT = 'SET_PAGE_FORM_ELEMENT'

export interface PageConfig {
    id: string
    module: string
    viewMode: string
    defaultController: string
    slots?: PageSlotState;
    forms?: PageFormState
}

export interface PageFormState {
    [formName: string]: PageFormDataState
}

interface FormElementState {
    [inputName: string]: any
}


export interface PageFormDataState {
    inputs: FormElementState
    errors: FormElementState
}

export interface PageState extends PageConfig {
    slots?: PageSlotState;
}

export interface PageSlotState {
    [index: string]: IDataElement | [] | any
}

interface InitPageAction {
    type: typeof INIT_PAGE
    config: PageConfig
}

interface SetPageViewAction {
    type: typeof SET_PAGE_VIEW
    data: string
}

interface SetSlotAction {
    type: typeof SET_PAGE_SLOT
    target: string
    data: IDataElement
}

interface SetFormAction {
    type: typeof SET_PAGE_FORM
    target: string
    data: IDataElement
}

interface SetFormElementAction {
    type: typeof SET_PAGE_FORM_ELEMENT
    target: string
    data: PageFormDataState
}

export type PageAction = InitPageAction | SetSlotAction | SetFormAction | SetFormElementAction | SetPageViewAction;

export const initPage = (config: PageConfig) => ({
    type: INIT_PAGE,
    config
});
