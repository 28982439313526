import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
//import BaseHorizontal from './components/Layout/BaseHorizontal';
//import AuthorizeRoute from './components/Autentication/AuthorizeRoute';
import ProtectedRoute from './components/Autentication/ProtectedRoute';

//import { ToastContainer } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;

const LoginPage = lazy(() => import('./components/Autentication/LoginPage'));
const LogoutPage = lazy(() => import('./components/Autentication/LogoutPage'));
const RecoveryPage = lazy(() => import('./components/Autentication/RecoveryPage'));

/*
const Welcome = lazy(() => import('./components/Welcome/Welcome'));
const Buttons = lazy(() => import('./components/Elements/Buttons'));
const Cards = lazy(() => import('./components/Elements/Cards'));
const TableStandard = lazy(() => import('./components/Tables/TableStandard'));
const FormStandard = lazy(() => import('./components/Forms/FormStandard'));
*/

const MiCuenta = lazy(() => import('./modules/base/cuenta/PerfilUsuario'));
const OrdenesServicioActivas = lazy(() => import('./modules/ordenes/active'));
const OrdenesServicioPorRecibir = lazy(() => import('./modules/ordenes/arrivals'));
const OrdenesServicioEnProceso = lazy(() => import('./modules/ordenes/departures'));
const OrdenesServicioPorFacturar = lazy(() => import('./modules/ordenes/invoice-pending'));
const CalendarioOrdenes = lazy(() => import('./modules/ordenes/calendar'));
const ListadoOrdenes = lazy(() => import('./modules/ordenes/datatable'));
const ListadoOrdenesCanceladas = lazy(() => import('./modules/ordenes/canceled'));
const ListadoPagosOrdenes = lazy(() => import('./modules/pagos/datatable'));
const OrdenServicioVer = lazy(() => import('./modules/ordenes/order-view'));
const Configuracion = lazy(() => import('./modules/settings/settings'));
const ListadoNotificacionesApp = lazy(() => import('./modules/settings/app-notifications/AppNotificationsPage'));
const ListadoNotificacionesCliente = lazy(() => import('./modules/settings/user-notifications/UserNotificationsPage'));
const ListadoRazonesCancelacion = lazy(() => import('./modules/settings/reason-cancellation/ReasonCancellationPage'));
const ListadoCupones = lazy(() => import('./modules/settings/coupons/CouponsPage'));
const ListadoAnuncios = lazy(() => import('./modules/settings/ads/AdsPage'));
const ListadoPrendas = lazy(() => import('./modules/settings/items/ItemsPage'));
const ListadoServicios = lazy(() => import('./modules/settings/services/ServicesPage'));
const ListadoDisponibilidad = lazy(() => import('./modules/settings/availability/AvailabilityPage'));
const ListadoClientes = lazy(() => import('./modules/settings/users/CustomersPage'));
const VerCliente = lazy(() => import('./modules/settings/users/CustomerView'));
const VerSocio = lazy(() => import('./modules/settings/users/PartnerView'));
const ListadoSocios = lazy(() => import('./modules/settings/users/PartnersPage'));
const ListadoEmpleados = lazy(() => import('./modules/settings/users/EmployeesPage'));
const ListadoTiendas = lazy(() => import('./modules/settings/companies/CompaniesPage'));

const DashboardV1 = lazy(() => import('./modules/reports/DashboardV1'));
const ReporteOrdenesPorArea = lazy(() => import('./modules/reports/OrdersByArea'));

const NotFoundPage = lazy(() => import('./modules/base/NotFound'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages: Array<string> = [
    /* See full project for reference */
    '/login',
    '/recover',
    '/logout',
    //'/404'
];

const Routes = ({ location }: RouteProps) => {
    const currentKey = location!.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'
    
    if (listofPages.indexOf(location!.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/* See full project for reference */}
                        <Route path="/login" component={waitFor(LoginPage)} />
                        <Route path="/recover" component={waitFor(RecoveryPage)} />
                        {/*<Route path="/404" component={waitFor(NotFoundPage)} />*/}
                        <ProtectedRoute path="/logout" component={waitFor(LogoutPage)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            //<ErrorBoundary /*report={(error:Error) => console.log(error)}*/ >
                <Base>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader />}>
                                    <Switch location={location}>
                                    
                                        <ProtectedRoute path="/home" component={waitFor(OrdenesServicioActivas)} />

                                        <ProtectedRoute path="/arrivals" component={waitFor(OrdenesServicioPorRecibir)} />
                                        <ProtectedRoute path="/departures" component={waitFor(OrdenesServicioEnProceso)} />
                                        <ProtectedRoute path="/invoice-pending" component={waitFor(OrdenesServicioPorFacturar)} />
                                        <ProtectedRoute path="/tasks" component={waitFor(CalendarioOrdenes)} />
                                        <ProtectedRoute path="/canceled" component={waitFor(ListadoOrdenesCanceladas)} />
                                        <ProtectedRoute path="/payments" component={waitFor(ListadoPagosOrdenes)} />
                                        <ProtectedRoute path="/orders/:id" component={waitFor(OrdenServicioVer)} />
                                        <ProtectedRoute path="/orders" component={waitFor(ListadoOrdenes)} />
                                        
                                        <ProtectedRoute path="/user-profile" component={waitFor(MiCuenta)} />
                                        
                                        <ProtectedRoute path="/dashboard" component={waitFor(DashboardV1)} />
                                        <ProtectedRoute path="/reports/orders-by-area" component={waitFor(ReporteOrdenesPorArea)} />

                                        <ProtectedRoute path="/settings/partners/:id" component={waitFor(VerSocio)} />
                                        <ProtectedRoute path="/settings/customers/:id" component={waitFor(VerCliente)} />
                                        <ProtectedRoute path="/settings/customers" component={waitFor(ListadoClientes)} />
                                        <ProtectedRoute path="/settings/partners" component={waitFor(ListadoSocios)} />
                                        <ProtectedRoute path="/settings/employees" component={waitFor(ListadoEmpleados)} />
                                        <ProtectedRoute path="/settings/services" component={waitFor(ListadoServicios)} />
                                        <ProtectedRoute path="/settings/items" component={waitFor(ListadoPrendas)} />
                                        <ProtectedRoute path="/settings/coupons" component={waitFor(ListadoCupones)} />
                                        <ProtectedRoute path="/settings/ads" component={waitFor(ListadoAnuncios)} />
                                        <ProtectedRoute path="/settings/availability" component={waitFor(ListadoDisponibilidad)} />
                                        <ProtectedRoute path="/settings/reason-cancellation" component={waitFor(ListadoRazonesCancelacion)} />
                                        <ProtectedRoute path="/settings/app-notifications" component={waitFor(ListadoNotificacionesApp)} />
                                        <ProtectedRoute path="/settings/user-notifications" component={waitFor(ListadoNotificacionesCliente)} />
                                        <ProtectedRoute path="/settings/companies" component={waitFor(ListadoTiendas)} />
                                        <ProtectedRoute path="/settings" exact={true} component={waitFor(Configuracion)} />

                                        {<ProtectedRoute path="/404" component={waitFor(NotFoundPage)} />}
                                        {<ProtectedRoute path="/" exact={true} component={waitFor(OrdenesServicioActivas)} />}
                                        
                                        {<Redirect from="*" to="/404" />}
                                        {/*<ProtectedRoute component={NotFoundPage} />*/}
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Base>
            //</ErrorBoundary>
        )
    }
}

export default withRouter(Routes);
