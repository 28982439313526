import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';
import apiReducer from './api.reducer';
import globalReducer from './global.reducer';
import pageReducer from './page.reducer';
import sessionReducer from './session.reducer';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    api: apiReducer,
    global: globalReducer,
    page: pageReducer,
    session: sessionReducer
});
