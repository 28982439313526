
export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export interface ApplicationUser {
    id: number;
    firstName: string;
    lastName?: string;
    username: string;
    groupName: string;
    token?: string;
    refreshToken?: string;
    expiration?: string;
    avatar?:string
}

export interface ApplicationUserToken {
    token: string
    refreshToken: string
}

export interface ApplicationData {
    name?: string
    version: string
    logo?: string
    timezone?: any
    language?: any
}

export enum ApiResultCodes {
    Ok = 0,
    Information = 1,
    Warning = 2,
    Error = -1,
    NotFound = 2
}

export enum UserProfile {
    Empleado = 1,
    Contratista = 2,
    Externo = 3,

    Cliente = 1,
    Socio = 2,
    Proveedor = 2,
    Supervisor = 3,
    Administrador = 4
} 
    

export interface ApiResult {
    code: ApiResultCodes;
    message?: string;
    data: any[];
}

export interface AppNotification {
    id: number
    title: string
    count: number
    icon: string
    url: string
}
