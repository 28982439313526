import React, { CSSProperties } from 'react';
//import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from "react-i18next"
import { ContentWrapper } from '@components/layout.module';
import { DefaultProps } from '@models/index';
import { Application } from '@services/app.service';
import { INIT_PAGE } from '@store/actions/actions';
import { Button, ButtonToolbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

export interface IPageBreadcrumb {
    title: string
    url?: string
}

export interface IPageAction {
    title: string
    url?: string
    color?: string
    className?: string
    icon?: string
    disabled?:boolean
    visible?:boolean
    onClick?: any // () => void
}

export type breadcrumbOpts = 'default' | 'right' | 'hide';

export interface IPageConfig {
    id:string
    module?: string
    defaultController?:string
    title: string 
    subtitle?: string
    headerCSS?: CSSProperties
    bcView?: breadcrumbOpts
    bc: IPageBreadcrumb[]
    actions?: IPageAction[]
    slots?: any
    forms?: any
    viewMode?:string
}

export interface IPageProps extends IPageConfig, WithTranslation {}

class Page extends React.Component<IPageProps> {
    constructor(props:IPageProps ) {
        super(props);
        const config = {
            id: this.props.id,
            module: this.props.module,
            defaultController: this.props.defaultController,
            viewMode: this.props.viewMode || "default",
            //bcView: this.props.bcView ? this.props.bcView : 'default',
            slots: [],
            forms: []
        }
        Application.dispatch(INIT_PAGE, { config });
    }

    componentWillUnmount() {
        const config = {
            slots: [],
            forms: []
        }
        Application.dispatch(INIT_PAGE, { config });
    }

    componentDidMount() {
        /*
        const config = {
            id: this.props.id,
            module: this.props.module,
            defaultController: this.props.defaultController,
            slots: []
        }
        Application.dispatch(INIT_PAGE, { config });
        */
    }

    renderBreadcrumbsItems(bc:IPageBreadcrumb[]) {
        // <a href={item.url} >{item.title}</a>
        const count = bc.length - 1;
        const items = bc.map( (item, index) =>{
            const key = ['page-bc-',index].join('-');
            if (index < count) 
                return <li key={key} className="breadcrumb-item"><Link to={item.url!} title={item.title}>{item.title}</Link></li>
            return <li key={key} className="breadcrumb-item active">{item.title}</li>
        });
        return items;
    }

    renderBreadCrumbs(bc: IPageBreadcrumb[], show:boolean, view?: breadcrumbOpts) {
        //console.log(bc, show, view)
        const customClass = view === "right" ? "breadcrumb ml-auto" : "breadcrumb breadcrumb px-0 pb-0"
        const template = 
            bc.length > 0 && show ?
            <ol className={customClass}>
                {this.renderBreadcrumbsItems(bc)}
            </ol> : 
            null 
         
        return template;
    }

    renderActionButtons(actions:IPageAction[]) {
        const items = actions.map( (item, index) =>{
            const key = ['page-action-',index].join('-');
            //fa-lg text-muted
            const iconClassName = [item.icon, item.color ? '':'text-muted'].join(" ");
            const className = item.className || ""
            if (!item.visible) return null;
            return <Button key={key} onClick={item.onClick} color={item.color} style={{margin: "0 4px 4px 0"}} disabled={item.disabled}>
                    { item.icon && item.icon.length > 0 ?
                        <i className={iconClassName} style={{padding: "0 5px", marginLeft: "-10px"}}></i>
                        : null
                    }  
                    {item.title}
                </Button>
        });
        return <ButtonToolbar>{items}</ButtonToolbar>;
    }

    render() {
        const { title, subtitle, headerCSS, bc, actions, bcView } = this.props;
        const bcViewOpts = bcView || 'default';

        return (
            <ContentWrapper>
                { title.length > 0 &&
                <div className="content-heading" style={ headerCSS? headerCSS : { padding: "10px 20px", fontSize: "0.775rem" } }>
                    <div>{title}
                        { subtitle? <small>{subtitle}</small> : null}
                        { this.renderBreadCrumbs(bc, bcViewOpts === "default" , bcView ) }
                    </div>
                    { this.renderBreadCrumbs(bc, bcViewOpts === "right", bcView ) }
                    {
                        actions && actions.length > 0 ?
                        <div className="ml-auto">
                            {this.renderActionButtons(actions)}
                        </div> :
                        null
                    }

                </div>}
                {this.props.children}
            </ContentWrapper>
        );
    }
}

export const PageContainer = withTranslation('translations')(Page);

