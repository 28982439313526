import * as React from 'react';
import { Col, ColProps } from 'reactstrap';

export interface IColumnProps extends ColProps {
    colsize: number[]
    className?: string
    style?: React.CSSProperties
} 

export class Column extends React.Component<IColumnProps> {
    parseColSize(size: number[]) {
        let sizeProps:any = {};
        //sizeProps.xl = size.length > 0 ? size[0] : 12;
        sizeProps.lg = size.length > 3 ? size[3] : size[size.length - 1];
        sizeProps.md = size.length > 2 ? size[2] : size[size.length - 1];
        sizeProps.sm = size.length > 1 ? size[1] : size[size.length - 1];
        sizeProps.xs = size.length > 0 ? size[0] : 12;
        return sizeProps;
    }
    render() {
        const { colsize } = this.props;
        const size = this.parseColSize(colsize);
        return <Col {...size} {...this.props} >{this.props.children}</Col>
    }
}